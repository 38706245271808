<!--
  Note: even if the locale is reactive and the link is updated to point to the correct document,
  iubenda doesn't re-initialize the content of the preview overlay.
 -->
<template>
  <!--
    @click.stop had to be added as it otherwise would (un)check the checkbox in the registration form.
    If that behavior creates issues otherwise, this has to be made conditional.
  -->
  <a
    class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed text-primary"
    :href="link"
    :title="label"
    @click.stop="onClick"
    v-text="label"
  />
</template>

<script>
import { iubenda } from '@/lib/features'

import mixin from './mixin'

export default {
  mixins: [mixin],

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    config: iubenda.config,
    scriptLoaded: false,
  }),

  computed: {
    label() {
      return this.$t(`legal.links.${this.type}`)
    },

    link() {
      const linkPath = {
        privacyPolicy: `privacy-policy/${this.documentId}`,
        cookiePolicy: `privacy-policy/${this.documentId}/cookie-policy`,
        terms: `terms-and-conditions/${this.documentId}`,
      }[this.type]

      return `https://www.iubenda.com/${linkPath}`
    },

    documentId() {
      return this.config?.documentId[this.locale]
    },
  },

  mounted() {
    if (!this.scriptLoaded) {
      this.loadScript()
    }
  },

  methods: {
    onClick() {
      this.$tracking.event('Legal Documents', 'Clicked', this.type)
    },

    loadScript() {
      // Check if script already exists
      if (document.querySelector('script[src="https://cdn.iubenda.com/iubenda.js"]')) {
        this.scriptLoaded = true
        return
      }

      const scriptElement = document.createElement('script')
      scriptElement.type = 'text/javascript'
      scriptElement.src = 'https://cdn.iubenda.com/iubenda.js'
      scriptElement.async = true

      // Append to document body or head instead of the <a> tag
      document.body.appendChild(scriptElement)
      this.scriptLoaded = true
    },
  },
}
</script>
