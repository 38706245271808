<template>
  <div>
    <v-skeleton-loader
      :style="{ width: $vuetify.display.mdAndUp ? '82%' : '100%' }"
      v-if="loading"
      class="mx-auto"
      type="article, actions"
    />
    <v-card
      v-else
      class="pa-3 pb-0 card"
    >
      <v-card-title class="mb-2 pa-0 title">{{ $t('dashboard.added-value.title') }}</v-card-title>
      <v-card-text class="pa-0 mb-1">
        <div class="d-flex justify-space-between">
          <div>
            <span class="label mb-1">{{ $t('dashboard.added-value.existing-user-labels.0') }}</span>
            <span class="d-flex">
              <span class="d-flex align-center">
                <v-avatar
                  color="rgba(255, 255, 255, 0.2)"
                  size="28"
                >
                  <v-icon
                    icon="mdi-bank"
                    color="white"
                  />
                </v-avatar>
              </span>
              <span class="grid">
                <span class="number ml-md-2 ml-1"> {{ totalLeadValue }} </span>
              </span>
            </span>
          </div>

          <div>
            <span class="label mb-1"
              >{{ $t('dashboard.added-value.existing-user-labels.1') }}
              <span v-if="!loading">{{ commission }}%</span>
            </span>
            <span class="d-flex">
              <span class="d-flex align-center">
                <v-avatar
                  color="rgba(255, 255, 255, 0.2)"
                  size="28"
                >
                  <v-icon
                    icon="mdi-percent"
                    color="white"
                  />
                </v-avatar>
              </span>
              <span class="grid">
                <span class="number ml-md-2 ml-1">{{ totalCommissionValue }}</span>
              </span>
            </span>
          </div>
        </div>
        <v-divider class="my-3" />
        <div class="save-time-money mb-4">
          <span
            class="label mr-4"
            style="max-width: 120px"
            >{{ $t('dashboard.added-value.existing-user-labels.2') }}</span
          >
          <span class="save-tme-money-numbers">
            <v-icon
              class="mr-1"
              color="white"
            >
              mdi-clock-time-four-outline
            </v-icon>
            <span class="number">{{ formatMinutesToHours(timeSaved) }}</span>
            <span class="px-3 vertical-divider">|</span>
            <v-icon
              class="mr-1"
              color="white"
            >
              mdi-cash-multiple
            </v-icon>
            <span class="number">{{ formatPrice(moneySaved) }}</span>
          </span>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script setup lang="ts">
import { computed, getCurrentInstance, PropType } from 'vue'

import formatMinutesToHours from '@/lib/time'
import currencyMixin from '@/mixins/currency.js'

const instance = getCurrentInstance()
const formatPrice = currencyMixin.methods.formatPrice.bind(instance.proxy)

const props = defineProps({
  statistics: Object as PropType<Record<string, any>>,
  totalTimeSaved: Number,
  totalMoneySaved: Number,
  loading: Boolean,
})

const loading = computed(() => props.loading)
const totalLeadValue = computed(() => formatPrice(props.statistics?.totalLeadValue))
const totalCommissionValue = computed(() =>
  formatPrice(props.statistics?.totalCommissionValue.value)
)
const commission = computed(() => formatPrice(props.statistics?.totalCommissionValue.commission))

const timeSaved = computed(() => props.totalTimeSaved)
const moneySaved = computed(() => props.totalMoneySaved)
</script>

<style scoped>
.progress-bar {
  margin: auto;
}
:deep(.blur-layer) {
  background: transparent;
}

.card {
  width: 87%;
  margin: 0 auto 16px auto;
  background: linear-gradient(180deg, #82c5dd 100%, #035855 100%);
}

@media (max-width: 960px) {
  .v-card {
    margin: 0;
    width: 100%;
  }
}
</style>
