<template>
  <div :style="cssProps">
    <v-expand-transition>
      <v-alert
        v-if="error"
        type="error"
        density="compact"
        variant="tonal"
        class="mb-4"
        rounded="0"
      >
        {{ getErrorMessage(error) }}
      </v-alert>
    </v-expand-transition>

    <div>
      <v-form
        name="auth-login"
        :class="{ markero: isPlatformMarkero }"
        @submit.prevent="login"
      >
        <v-text-field
          v-model.trim="email"
          :label="$t('login.form.email')"
          type="email"
          variant="outlined"
          color="primary"
          hide-details
          data-testid="login-email"
          :class="{ 'mb-5': isPlatformMarkero, 'mb-4': !isPlatformMarkero }"
        >
          <template #prepend-inner>
            <v-icon icon="mdi-email-outline" />
          </template>
        </v-text-field>

        <v-text-field
          v-model="password"
          :label="$t('login.form.password')"
          type="password"
          hide-details
          color="primary"
          variant="outlined"
          prepend-inner-icon="mdi-lock-outline"
          data-testid="login-password"
          :class="{ 'mb-2': !isPlatformMarkero }"
        />

        <div
          class="d-flex justify-end"
          :class="{ 'mt-2 mb-4': !isPlatformMarkero }"
        >
          <router-link
            :to="{ name: 'password-reset-request' }"
            :style="{
              color: isPlatformMarkero
                ? getThemeColor('markero-blue')
                : 'rgb(var(--v-theme-primary))',
            }"
            class="font-weight-bold text-decoration-none forgot-password-link"
          >
            {{ $t('login.form.forgotPassword') }}
          </router-link>
        </div>

        <div class="px-0 d-flex flex-column">
          <loading-button
            :color="isPlatformMarkero ? 'markero-blue' : 'primary'"
            type="submit"
            :class="{ 'my-8 login-btn': isPlatformMarkero, 'mb-5': !isPlatformMarkero }"
            :height="50"
            data-testid="login-submit"
            :loading="submitting"
          >
            {{ $t('login.form.submit') }}
          </loading-button>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { useTheme } from 'vuetify'

import LoadingButton from '@/components/loading-button'
import { supportContact } from '@/lib/features'
import brandingMixin from '@/mixins/branding'

export default {
  name: 'LoginForm',
  components: { LoadingButton },
  mixins: [brandingMixin],

  data: () => ({
    email: '',
    password: '',
    error: null,
    submitting: false,
  }),

  computed: {
    cssProps() {
      return {
        '--dark-blue': this.getThemeColor('markero-dark-blue'),
        '--dark-grey': this.getThemeColor('markero-dark-grey'),
      }
    },
    theme() {
      return useTheme()
    },
    errorMessages() {
      return {
        emptyFields: this.$t('login.errors.emptyFields'),
        accessDenied: this.$t('login.errors.accessDenied'),
        tooManyAttempts: this.$t('login.errors.tooManyAttempts'),
        blockedUser: this.$t('login.errors.blockedUser', {
          supportContact: supportContact.config.email,
        }),
        unknown: this.$t('login.errors.unknown'),
      }
    },
  },

  methods: {
    getThemeColor(colorName) {
      const currentTheme = this.theme.global.current.value
      return currentTheme.colors[colorName] || currentTheme.colors.primary
    },
    async login(evt) {
      evt.preventDefault()
      this.error = null

      if (!this.email || !this.password) {
        this.error = 'emptyFields'
        return
      }

      this.submitting = true
      try {
        await this.$auth.login(this.email, this.password)
      } catch (err) {
        this.error =
          err.code === 'access_denied'
            ? 'accessDenied'
            : err.code === 'too_many_attempts'
              ? 'tooManyAttempts'
              : err.code === 'blocked_user'
                ? 'blockedUser'
                : 'unknown'

        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'Login failed',
        })
        Sentry.captureException(err)
      } finally {
        this.submitting = false
      }
    },
    getErrorMessage(code) {
      return this.errorMessages[code]
    },
  },
}
</script>

<style scoped>
/* Markero Styles */
.markero .forgot-password-link,
.markero .login-btn,
:deep(.markero .v-label) {
  font-family: 'Poppins', sans-serif;
}

/* Forgot Password Link Styles */
.markero .forgot-password-link {
  margin-top: 10px;
}

:deep(.markero .forgot-password-link:hover) {
  color: var(--dark-blue) !important;
}

/* Login Button Styles */
.markero .login-btn {
  font-size: 16px;
  font-weight: 700;
  color: white;
  height: fit-content !important;
  padding: 14px 34px !important;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0 rgba(47, 22, 232, 0.25) !important;
  text-transform: none;
  font-style: normal;
  line-height: normal;
}

/* Vuetify Specific Overrides */
:deep(.markero .v-btn:hover::before) {
  background-color: var(--dark-blue);
}

:deep(.markero .v-field--variant-outlined .v-field__outline) {
  color: #e4e4e4;
}

:deep(.markero .v-field input) {
  color: var(--dark-grey);
}

:deep(.markero .v-field__prepend-inner) {
  padding-top: 0px;
}

:deep(.v-field .v-label) {
  top: 50%;
}

:deep(.markero .v-field--variant-outlined) {
  min-height: 30px;
}

:deep(.markero .v-field--focused .v-icon) {
  color: rgb(var(--v-theme-primary));
}
</style>
