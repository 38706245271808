query featuresForCompany($companyId: ID!) {
  featuresForCompany(companyId: $companyId) {
    slug
    name
    isEnabled
    isDefaultActive
    isUpsellingEnabled
    config
    isActive
  }
}
