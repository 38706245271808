/**
 * The productPackageQuery is used to store the essential data from the selected package plan.
 * Later this stored data is added as a query parameter to the URL and used in the payment process such as:
 * - Data shown to the user in the payment dialog
 * - Data sent to the backend to create the payment
 * When the payment is done the productPackageQuery data is removed.
 */
export function getProductPackageQuery() {
  const storedQuery = sessionStorage.getItem('productPackageQuery')
  if (!storedQuery) {
    return null
  }

  try {
    return JSON.parse(storedQuery)
  } catch (e) {
    return null
  }
}

export function removeProductPackageQuery() {
  sessionStorage.removeItem('productPackageQuery')
}
