export const FULL = 'full'
export const DATE_SHORT = 'dateShort'
export const DATE_LONG = 'dateLong'
export const ISO_DATE = 'dateISO'
export const NAMED_MONTH_WITH_YEAR = 'namedMonthWithYear'
export const TIME = 'time'
export const NAMED_WEEKDAY_WITH_YEAR_WITH_TIME = 'namedWeekdayWithYearWithTime'
export const NAMED_WEEKDAY_WITH_YEAR = 'namedWeekdayWithYear'

export default {
  methods: {
    formatDate(dateString, variant = FULL) {
      if (!dateString) {
        return ''
      }
      if (variant === ISO_DATE) {
        return new Date(dateString).toISOString().slice(0, 10)
      }

      const { locale = 'de' } = this.$auth.user

      const is = (...variants) => variants.indexOf(variant) !== -1

      return new Intl.DateTimeFormat(locale, {
        year: is(
          FULL,
          DATE_LONG,
          NAMED_MONTH_WITH_YEAR,
          NAMED_WEEKDAY_WITH_YEAR_WITH_TIME,
          NAMED_WEEKDAY_WITH_YEAR
        )
          ? 'numeric'
          : '2-digit',
        month: is(NAMED_MONTH_WITH_YEAR) ? 'long' : '2-digit',
        weekday: is(NAMED_WEEKDAY_WITH_YEAR_WITH_TIME, NAMED_WEEKDAY_WITH_YEAR) ? 'long' : 'short',
        ...(!is(NAMED_MONTH_WITH_YEAR) && { day: '2-digit' }),
        ...(is(FULL, TIME, NAMED_WEEKDAY_WITH_YEAR_WITH_TIME) && {
          hour: '2-digit',
          minute: '2-digit',
        }),
        hour24: is(NAMED_WEEKDAY_WITH_YEAR_WITH_TIME),
      }).format(new Date(dateString))
    },
  },
}
