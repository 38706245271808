const ProductType = Object.freeze({
  BASIC: 'BASIC',
  EXPERT: 'EXPERT',
  LIGHT: 'LIGHT',
  PIONEER: 'PIONEER',
  ADVANCED: 'ADVANCED',
  ADVANCED_TRIAL: 'ADVANCED_TRIAL',
  BEGINNER: 'BEGINNER',
  BEGINNER_TRIAL: 'BEGINNER_TRIAL',
})

const isExpertUser = ({ productType }) => productType === ProductType.EXPERT
const isBasicUser = ({ productType }) => productType === ProductType.BASIC
const isLightUser = ({ productType }) => productType === ProductType.LIGHT
const isAuthenticatedAndExpertUser = ({ isAuthenticated, user }) =>
  isAuthenticated && user.productType === ProductType.EXPERT
const isAuthenticatedAndBasicUser = ({ isAuthenticated, user }) =>
  isAuthenticated && user.productType === ProductType.BASIC
const isAuthenticatedAndLightUser = ({ isAuthenticated, user }) =>
  isAuthenticated && user.productType === ProductType.LIGHT

export {
  isAuthenticatedAndBasicUser,
  isAuthenticatedAndExpertUser,
  isAuthenticatedAndLightUser,
  isBasicUser,
  isExpertUser,
  isLightUser,
  ProductType,
}
