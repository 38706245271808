<template>
  <v-card
    v-if="endDate"
    border
    class="header"
  >
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <v-icon
            class="mr-2"
            color="black"
          >
            mdi-account-cancel
          </v-icon>
          <span class="break-text">
            {{
              $t('alerts.canceled-subscription.title', {
                platform: readablePlatformName,
                date: formatDate(contractDetails?.endDate, 'DATE_LONG'),
              })
            }}
          </span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <p>
            <span v-if="!isPlatformMarkero">
              {{ $t('alerts.canceled-subscription.question') }}<br />
              {{ $t('alerts.canceled-subscription.info', { platform: readablePlatformName })
              }}<br />
            </span>
            <a href="/settings/contract">{{ $t('alerts.canceled-subscription.link') }}</a>
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import { billwerkApi } from '@/modules/billwerk/lib'
import SELF_SERVICE_PORTAL_URL from '@/modules/settings/accountSettings/queries/SelfServicePortalUrl.gql'

export default {
  mixins: [brandingMixin],
  data() {
    return {
      endDate: null,
    }
  },
  apollo: {
    token: {
      query: SELF_SERVICE_PORTAL_URL,
      async update(data) {
        const token = data.billingSelfServicePortalDetails?.Token
        if (token) {
          const { Contract } = await billwerkApi.getContractDetails(token)
          this.endDate = Contract.EndDate ? new Date(data.Contract.EndDate) : null
        }
      },
    },
  },
}
</script>

<style scoped>
.break-text {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: calc(100% - 30px);
}
</style>
