import * as Sentry from '@sentry/browser'

import { getSubscriptionJS } from './getSubscriptionJS'

/**
 * Initializes the payment service and returns it.
 * @param {string|undefined} providerReturnUrl - The URL to which the provider should return (optional).
 * @returns {Promise<Object>} - A promise that resolves with the payment service instance or rejects with an error.
 */
export async function getPaymentService(providerReturnUrl) {
  try {
    const SubscriptionJS = await getSubscriptionJS()
    const paymentConfig = {
      publicApiKey: import.meta.env.VITE_APP_BILLWERK_PUBLIC_API_KEY,
      providerReturnUrl,
    }

    return new Promise((resolve, reject) => {
      const paymentService = new SubscriptionJS.Payment(
        paymentConfig,
        () => resolve(paymentService),
        (error) => {
          Sentry.captureException(error)
          reject(error)
        }
      )
    })
  } catch (error) {
    return Promise.reject(new Error('Failed to initialize SubscriptionJS: ' + error.message))
  }
}
