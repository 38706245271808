<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 42.5 85"
    enable-background="new 0 0 42.5 85"
    xml:space="preserve"
  >
    <gradient-helper />

    <path
      :fill="fill ? 'url(#svg-gradient)' : 'none'"
      :stroke="stroke ? 'url(#svg-gradient)' : 'none'"
      :stroke-width="strokeWidth"
      d="M0.9,42.5v40.7c22.5,0,40.7-18.2,40.7-40.7l0-40.7c0,0,0,0,0,0C13.9,2.1,0.5,25.7,0.9,42.5L0.9,42.5z"
    />
  </svg>
</template>

<script>
import svg from '@/mixins/svg'

import GradientHelper from './GradientHelper.vue'
export default {
  components: { GradientHelper },
  mixins: [svg],
}
</script>
