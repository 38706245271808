<template>
  <v-card
    border
    class="px-0 pt-4 d-flex flex-column"
  >
    <v-card-text class="pa-0">
      <div
        ref="checklist"
        id="checklist"
        :class="{ 'small-checklist': loading, 'mobile-checklist': !loading }"
        class="checklist"
      >
        <div v-if="!loading" />
        <div
          v-else
          class="w-full d-flex justify-space-around"
        >
          <v-skeleton-loader
            v-if="loading"
            style="width: 100%"
            class="mx-auto"
            type="article, actions"
          />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { productFruits } from 'product-fruits'

import featureNames from '@/lib/featureNames'
import { showSnackbarMessage } from '@/lib/snackbarMessages'

export default {
  data() {
    return {
      loading: true,
      hasChecklist: true,
      checklistId: null,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.smAndDown
    },
  },

  mounted() {
    const feature = this.$features.feature(featureNames.PRODUCTFRUITS)
    this.checklistId = this.isMobile
      ? feature?.config.checklistCodeFirstStepsMobile
      : feature?.config.checklistCodeFirstSteps
    if (this.checklistId) {
      productFruits.safeExec(() => {
        this.setupChecklist()
      })
    }
  },
  methods: {
    setupChecklist() {
      this.injectChecklistToElement()
      this.listenToListChanges()
    },
    injectChecklistToElement() {
      const el = document.getElementById('checklist')
      try {
        window.productFruits.api.checklists.injectToElement(this.checklistId, el)
      } catch (e) {
        console.error('Could not load checklist', e)
        this.hasChecklist = false
      }
      this.loading = false
    },
    listenToListChanges() {
      const checklists = window.productFruits.api.checklists
      checklists.listen('dismissed', this.handleDismissedEvent)
      checklists.listen('completed', this.handleCompletedEvent)
    },

    handleDismissedEvent() {
      showSnackbarMessage('info', this.$t('alerts.product-fruits.info'))
      this.hasChecklist = false
    },

    handleCompletedEvent() {
      showSnackbarMessage('info', this.$t('alerts.product-fruits.success'))
      this.hasChecklist = false
    },
  },
}
</script>

<style scoped>
.checklist {
  height: 450px;
  overflow-y: auto;
}
.small-checklist {
  height: 100px;
}

@media (max-width: 320px) {
  .mobile-checklist {
    height: 1150px;
  }
}

@media (min-width: 321px) and (max-width: 393px) {
  .mobile-checklist {
    height: 870px;
  }
}

@media (min-width: 394px) and (max-width: 430px) {
  .mobile-checklist {
    height: 820px;
  }
}
</style>
