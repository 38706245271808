<template>
  <div :class="{ funnel: !analyticsImpressionLoading && !landingpagesLoading }">
    <v-skeleton-loader
      v-if="analyticsImpressionLoading || landingpagesLoading"
      :style="{ width: $vuetify.display.mdAndUp ? '92%' : '100%' }"
      class="mx-auto"
      type="article, actions"
    />

    <v-card
      v-else
      class="pa-3 pb-0 card"
    >
      <v-card-title class="mb-2 pa-0 title">{{ $t('dashboard.impressions.title') }}</v-card-title>
      <BlurredBackdropUpselling v-if="needsUpgrade" />
      <template v-else>
        <Feature :feature-slug="featureNames.LANDINGPAGE">
          <v-card-text
            v-if="isNewUser"
            class="pa-0"
          >
            <div class="d-md-flex justify-md-space-between mb-4">
              <span class="label d-flex d-md-block my-2 my-md-0 mr-1">
                {{
                  $t('dashboard.impressions.new-user-labels.0', {
                    count: numberOfLandingpages?.toLocaleString(),
                  })
                }}
              </span>
              <v-btn
                @click="integrateWidgets()"
                color="white"
              >
                <span class="button text-primary">
                  {{ $t('buttons.dashboard.impressions.new-user-labels.0') }}
                </span>
              </v-btn>
            </div>
          </v-card-text>

          <v-card-text
            v-else
            class="pa-0"
          >
            <div class="d-flex justify-space-between flex-wrap mb-4">
              <div>
                <span class="label mb-1">{{
                  $t('dashboard.impressions.existing-user-labels.0')
                }}</span>
                <span class="d-flex">
                  <span class="d-flex align-center">
                    <v-avatar
                      color="rgba(255, 255, 255, 0.2)"
                      size="28"
                    >
                      <v-icon
                        icon="mdi-eye"
                        color="white"
                      />
                    </v-avatar>
                  </span>
                  <span class="grid">
                    <span class="number ml-md-2 ml-1">{{
                      analyticsImpression?.toLocaleString() || 0
                    }}</span>
                  </span>
                </span>
              </div>
              <div>
                <span class="label mb-1">{{
                  $t('dashboard.impressions.existing-user-labels.1')
                }}</span>
                <span class="d-flex">
                  <span class="d-flex align-center">
                    <v-avatar
                      color="rgba(255, 255, 255, 0.2)"
                      size="28"
                    >
                      <v-icon
                        icon="mdi-button-pointer"
                        color="white"
                      />
                    </v-avatar>
                  </span>
                  <span class="grid">
                    <span class="number ml-md-2 ml-1"
                      >{{ numberOfLandingpages?.toLocaleString() || 0 }}
                    </span>
                  </span>
                </span>
              </div>
              <div
                class="mt-4 mt-xl-0"
                :style="{ width: $vuetify.display.lgAndDown ? '100%' : 'auto' }"
                v-if="!isExpertUser($auth.user)"
              >
                <v-btn
                  @click="getMore()"
                  style="width: 100%"
                  color="white"
                >
                  <span class="button text-primary">
                    {{ $t('buttons.dashboard.impressions.existing-user-labels.0') }}
                  </span>
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </Feature>
      </template>
    </v-card>
  </div>
</template>
<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import { computed, getCurrentInstance, inject, ref } from 'vue'
import { useRouter } from 'vue-router'

import BlurredBackdropUpselling from '@/components/BlurredBackdropUpselling.vue'
import featureNames from '@/lib/featureNames'
import { isExpertUser } from '@/lib/productTypes'
import featureMixin from '@/mixins/feature'
import GET_ANALYTICS_IMPRESSIONS from '@/modules/dashboard/GetAnalyticsImpressionForCompany'
import GET_NUMBER_OF_LANDINGPAGES from '@/modules/dashboard/GetNumberOfLandingpages.gql'
import { Feature } from '@/plugins/feature-config'
const router = useRouter()
const { appContext } = getCurrentInstance()!
const $tracking = appContext.config.globalProperties.$tracking
const instance = getCurrentInstance()
const getFeature = featureMixin.methods.getFeature.bind(instance.proxy)
const $auth = inject('auth')

defineProps({
  isNewUser: Boolean,
})

const { result: landingpages, loading: landingpagesLoading } = useQuery(GET_NUMBER_OF_LANDINGPAGES)
const numberOfLandingpages = computed(() => landingpages.value?.numberOfLandingpages)

const { result: analytics, loading: analyticsImpressionLoading } =
  useQuery(GET_ANALYTICS_IMPRESSIONS)
const analyticsImpression = computed(() => analytics.value?.analyticsImpression)

const landingpageFeature = computed(() => getFeature(featureNames.LANDINGPAGE))
const needsUpgrade = ref(!landingpageFeature.value.isActive)

const getMore = async () => {
  $tracking.event('Dashboard', 'Clicked', 'Landingpages')
  needsUpgrade.value = true
}
const integrateWidgets = async () => {
  $tracking.event('Dashboard', 'Clicked', 'Landingpages Integrate Widgets')
  router.push({ name: 'landingpages', params: { slug: 'guide' } })
}
</script>

<style scoped>
:deep(.blur-layer) {
  background: transparent;
}
.grid {
  display: grid;
}

.funnel::after {
  content: '';
  position: absolute;
  top: 99%;
  left: 30px;
  right: 30px;
  border-top: solid 20px #279491;
  border-left: solid 50px transparent;
  border-right: solid 50px transparent;
}
.card {
  width: 92%;
  margin: 0 auto;
  background: #229e9a;
}

@media (max-width: 960px) {
  .v-card {
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 600px) {
  :deep(.centered-alert) {
    width: 100% !important;
  }
}
</style>
