import { shadeColor } from '@/lib/color'

import brandingMixin from './branding'

export default {
  mixins: [brandingMixin],
  props: {
    fill: {
      type: Boolean,
      default: false,
    },
    stroke: {
      type: Boolean,
      default: false,
    },
    strokeWidth: {
      type: Number,
      default: 2,
    },
    fillColorStart: {
      type: String,
      default: '',
    },
    fillColorStop: {
      type: String,
      default: '',
    },
    strokeColorStart: {
      type: String,
      default: '',
    },
    strokeColorStop: {
      type: String,
      default: '',
    },
  },
  computed: {
    fillStart() {
      return (
        this.fillColorStart ||
        this.gradientColors.start ||
        this.$vuetify.theme.global?.current?.colors.primary
      )
    },
    fillStop() {
      return (
        this.fillColorStop ||
        this.gradientColors.end ||
        shadeColor(this.$vuetify.theme.global?.current?.colors.primary, 80)
      )
    },
    strokeStart() {
      return (
        this.strokeColorStart ||
        this.gradientColors.start ||
        this.$vuetify.theme.global?.current?.colors.primary
      )
    },
    strokeStop() {
      return (
        this.strokeColorStop ||
        this.gradientColors.end ||
        shadeColor(this.$vuetify.theme.global?.current?.colors.primary, 80)
      )
    },
  },
}
