<template>
  <v-list-item
    id="company-logo"
    to="/dashboard"
    exact
    lines="two"
    link
    class="px-2 rounded-lg"
    @click="trackClick()"
  >
    <template #prepend>
      <v-avatar
        tile
        v-if="!isDrawerOpen || showIconWhenExpanded"
      >
        <NavigationImage
          :width="40"
          :alt="$t('navigation.logo.alt-texts.0')"
          :height="40"
          :src="logoIcon"
        />
      </v-avatar>
    </template>

    <v-list-item-title v-if="isDrawerOpen">
      <NavigationImage
        :alt="$t('navigation.logo.alt-texts.1')"
        :src="logoName"
      />
    </v-list-item-title>
  </v-list-item>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'

import logoIcon from '@/assets/img/logo-icon.png'
import logoName from '@/assets/img/logo-name.png'
import { appBranding } from '@/lib/features'

import NavigationImage from './NavigationImage.vue'
defineProps<{
  isDrawerOpen?: PropType<boolean>
}>()

const emit = defineEmits<{
  'track-click': () => PropType<void>
}>()

const showIconWhenExpanded = !appBranding.config.hideIconInExpandedNavigation

const trackClick = () => emit('track-click')
</script>

<style scoped>
#company-logo {
  border-radius: 5px;
}

#company-logo :deep([class*='overlay']) {
  background: unset;
}

@media (max-width: 1260px) {
  #company-logo {
    height: 68px;
  }
}
</style>
