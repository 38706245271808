<template>
  <v-container
    class="py-2 layout-container d-flex flex-column ga-2 ga-md-4"
    :class="{ 'presentation-layout': isFeatureActive(featureNames.DASHBOARD_PRESENTATION) }"
  >
    <Feature :feature-slug="featureNames.COMPANY">
      <Feature :feature-slug="featureNames.DASHBOARD_PRESENTATION">
        <DashboardPresentationHeader />
        <template v-slot:disabled>
          <DashboardStandardHeader />
        </template>
      </Feature>
    </Feature>

    <Permission permission="settings-subscription:read">
      <DunningAlert />
    </Permission>

    <DeletionAlert />

    <Permission permission="settings-subscription:read">
      <CanceledSubscriptionAlert />
    </Permission>

    <Feature :feature-slug="featureNames.CRM">
      <ActivationReminder />
    </Feature>

    <Feature :feature-slug="featureNames.SIGNUP">
      <UserMailVerification />
    </Feature>

    <EmailVerification
      v-if="showEmailVerification"
      :default-sender="defaultSenderResults?.defaultSender"
    />

    <Feature
      v-slot="{ feature }"
      :feature-slug="featureNames.PRODUCTFRUITS"
    >
      <ProductFruitsChecklist v-if="feature.config.checklistCodeFirstSteps" />
    </Feature>

    <Feature :feature-slug="featureNames.DASHBOARD_PRESENTATION">
      <DashboardPresentation
        :leads="leadsResults?.leads"
        :statistics="statisticsResults?.statistics"
        :loading="loading"
      />
      <template v-slot:disabled>
        <DashboardStandard
          :default-sender="defaultSenderResults?.defaultSender"
          :leads="leadsResults?.leads"
          :statistics="statisticsResults?.statistics"
          :loading="loading"
        />
      </template>
    </Feature>
  </v-container>
</template>

<script setup lang="ts">
import { useApolloClient, useQuery } from '@vue/apollo-composable'
import { computed, defineAsyncComponent, getCurrentInstance, inject, ref } from 'vue'

import Permission from '@/components/Permission'
import featureNames from '@/lib/featureNames'
import featureMixin from '@/mixins/feature'
import UserMailVerification from '@/modules/auth/UserMailVerification.vue'
import ActivationReminder from '@/modules/crm/components/ActivationReminder.vue'
import CanceledSubscriptionAlert from '@/modules/dashboard/CanceledSubscriptionAlert.vue'
import DashboardPresentation from '@/modules/dashboard/DashboardPresentation.vue'
import DashboardPresentationHeader from '@/modules/dashboard/DashboardPresentationHeader.vue'
import DashboardStandard from '@/modules/dashboard/DashboardStandard.vue'
import DashboardStandardHeader from '@/modules/dashboard/DashboardStandardHeader.vue'
import DeletionAlert from '@/modules/dashboard/DeletionAlert.vue'
import DunningAlert from '@/modules/dashboard/DunningAlert.vue'
import HOPPERMATION_DEFAULT_SENDER from '@/modules/emailVerification/queries/HoppermationDefaultSender.gql'
import HOPPERMATION_ORGANIZATION from '@/modules/emailVerification/queries/HoppermationOrganization.gql'
import LEADS from '@/modules/map/LeadsWithLocation.gql'
import ProductFruitsChecklist from '@/modules/productFruitsChecklist/index.vue'
import STATISTICS from '@/modules/statistics/Statistics.gql'
import { Feature } from '@/plugins/feature-config'

const EmailVerification = defineAsyncComponent(() => import('@/modules/emailVerification'))

const role = inject('role')

const { client: apolloClient } = useApolloClient()
const instance = getCurrentInstance()
const $auth = inject('auth')
const getFeature = featureMixin.methods.getFeature.bind(instance.proxy)
const isFeatureActive = featureMixin.methods.isFeatureActive.bind(instance.proxy)

const { result: leadsResults } = useQuery(
  LEADS,
  {},
  {
    skip: !role.isAuthorizedFor('lead:read'),
  }
)

const { result: defaultSenderResults } = useQuery(
  HOPPERMATION_DEFAULT_SENDER,
  {
    companyId: $auth.user.companyId,
  },
  {
    skip: !role.isAuthorizedFor('settings-email:read'),
  }
)

const { result: organizationResults } = useQuery(HOPPERMATION_ORGANIZATION, {
  companyId: $auth.user.companyId,
})

const variables = ref({
  companyId: $auth.user.companyId,
})

const { result: statisticsResults, loading } = useQuery(STATISTICS, variables)

const showEmailVerification = computed(() => {
  const hoppermationFeature = getFeature(featureNames.HOPPERMATION)
  return (
    !apolloClient.loading &&
    hoppermationFeature.isActive &&
    role.isAuthorizedFor('settings-email:read') &&
    !hoppermationFeature.config?.hasStandardSender &&
    (organizationResults?.value?.organization?.status !== 'ACTIVE' ||
      !defaultSenderResults?.value?.isAcknowledged)
  )
})
</script>

<style>
.map-loader .v-skeleton-loader__image {
  height: 400px !important;
  border: none;
}

.toggle-btn {
  position: relative;
  left: 95%;
  top: 32px;
  z-index: 10;
}
@media (max-width: 1025px) {
  .toggle-btn {
    left: 90%;
  }
}

@media (min-width: 1265px) {
  .chart-wrapper {
    max-height: 80px !important;
    position: relative;
    bottom: 20px;
  }
}

@media (max-width: 960px) {
  .presentation-layout {
    background-color: #ffffff;
    .v-card {
      margin: 0;
      border: none;
    }
  }
}
</style>
