<template>
  <div class="d-flex flex-column ga-2 ga-md-4">
    <Permission permission="lead:read-all">
      <Feature
        v-slot="{ feature }"
        :feature-slug="featureNames.COMPANY"
      >
        <StatisticsComponent
          v-if="(!showEmailVerification || isSenderVerified) && feature.config.showLeadStatistics"
        />
      </Feature>
    </Permission>

    <Permission permission="lead:invite-bulk">
      <Feature :feature-slug="featureNames.ACQUISITION_BOOSTER">
        <AcquisitionBoosterDashboard
          :invitation-statistics-for-company="
            invitationStatisticsResults?.invitationStatisticsForCompany
          "
        />
      </Feature>
    </Permission>

    <Permission permission="ai:read">
      <Feature :feature-slug="featureNames.CONTENT_CREATOR">
        <ContentCreatorDasboard
          :invitation-statistics-for-company="
            invitationStatisticsResults?.invitationStatisticsForCompany
          "
        />
      </Feature>
    </Permission>

    <Permission permission="lead:read">
      <Feature
        v-slot="{ feature }"
        :feature-slug="featureNames.COMPANY"
      >
        <v-row>
          <v-col
            cols="12"
            :md="feature.config.showLeadInteractivity ? 6 : 12"
          >
            <Feature
              :feature-slug="featureNames.VALUATION"
              class="w-100"
            >
              <lead-map
                v-if="leads"
                :leads="leads"
              />
              <v-card
                v-else
                border
                class="mt-4 map-loader"
                style="height: 400px"
              >
                <v-skeleton-loader
                  height="400px"
                  type="image"
                />
              </v-card>
            </Feature>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <lead-interactivity v-if="feature.config.showLeadInteractivity" />
          </v-col>
        </v-row>
      </Feature>
    </Permission>
  </div>
</template>

<script setup lang="ts">
import { useApolloClient, useQuery } from '@vue/apollo-composable'
import { computed, defineAsyncComponent, getCurrentInstance, inject, PropType } from 'vue'

import Permission from '@/components/Permission'
import featureNames from '@/lib/featureNames'
import featureMixin from '@/mixins/feature'
import INVITATION_STATISTICS_FOR_COMPANY from '@/modules/acquisitionBooster/InvitationStatisticsForCompany.gql'
import HOPPERMATION_ORGANIZATION from '@/modules/emailVerification/queries/HoppermationOrganization.gql'
import LeadMap from '@/modules/map/index.vue'
import StatisticsComponent from '@/modules/statistics/index.vue'
import LeadInteractivity from '@/modules/statistics/LeadInteractivity.vue'
import { Feature } from '@/plugins/feature-config'

const AcquisitionBoosterDashboard = defineAsyncComponent(
  () => import('@/modules/acquisitionBooster/AcquisitionBoosterDashboard.vue')
)
const ContentCreatorDasboard = defineAsyncComponent(
  () => import('@/modules/contentCreator/ContentCreatorDasboard/index.vue')
)
const instance = getCurrentInstance()
const $auth = inject('auth')
const { client: apolloClient } = useApolloClient()
const getFeature = featureMixin.methods.getFeature.bind(instance.proxy)

const isFeatureActive = featureMixin.methods.isFeatureActive.bind(instance.proxy)
const role = inject('role')

const props = defineProps({
  defaultSender: Object as PropType<Record<string, any>>,
  statistics: Object as PropType<Record<string, any>>,
  leads: Object as PropType<Record<string, any>>,
  loading: Boolean,
})

const { result: organizationResults } = useQuery(HOPPERMATION_ORGANIZATION, {
  companyId: $auth.user.companyId,
})

const { result: invitationStatisticsResults } = useQuery(
  INVITATION_STATISTICS_FOR_COMPANY,
  {},
  {
    skip:
      !isFeatureActive(featureNames.ACQUISITION_BOOSTER) ||
      !role.isAuthorizedFor('lead:invite-bulk'),
  }
)

const showEmailVerification = computed(() => {
  const hoppermationFeature = getFeature(featureNames.HOPPERMATION)
  return (
    !apolloClient.loading &&
    hoppermationFeature.isActive &&
    role.isAuthorizedFor('settings-email:read') &&
    !hoppermationFeature.config?.hasStandardSender &&
    (organizationResults?.value?.organization?.status !== 'ACTIVE' ||
      !props.defaultSender?.isAcknowledged)
  )
})

const isSenderVerified = computed(() => {
  return props.defaultSender?.isVerified && !props.defaultSender?.isAcknowledged
})
</script>
