import { getInstance as getAuthInstance } from '../auth/index'
import { authGuard } from '../guards/auth'

export function hasCompanyGuard() {
  return (to, from, next) => {
    authGuard(() => {
      const auth = getAuthInstance()

      if (!auth.isAuthenticated || ['company-creation', 'logout'].includes(to.name)) {
        next()
        return
      }

      if (to.name === 'company-creation' && auth.user.companyId) {
        next({ name: 'dashboard', replace: true })
        return
      }

      if (!auth.user.companyId) {
        next({ name: 'company-creation', replace: true })
        return
      }

      next()
    })
  }
}
