import bus, { eventNames } from '@/lib/eventBus'

type SnackbarType = 'success' | 'error' | 'warning' | 'info'
type SnackbarTexts = Record<string, string>

export const showSnackbarMessage = (
  type: SnackbarType,
  text: string,
  texts?: SnackbarTexts
): void => {
  bus.$emit(eventNames.SHOW_SNACKBAR, {
    color: type,
    text: text,
    texts,
  })
}
