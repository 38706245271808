import featureNames from '@/lib/featureNames'
import { getInstance } from '@/plugins/feature-config'
import AVV from '@/queries/Avv.gql'

import { getInstance as getAuthInstance } from '../auth/index'
import { authGuard } from '../guards/auth'

/**
 * Checks if current user is responsible for the avv signing.
 * If so and an (updated) avv exists and it forces the user to accept it before using the app.
 * Routes to the avv page, if new avv exists or first time login of responsible user.
 *
 * @param {string} apolloProvider apollo instance
 * @returns {void}
 * @throws {Error}
 */
export function getAvvGuard(apolloProvider) {
  return (to, from, next) => {
    authGuard(async () => {
      try {
        const auth = getAuthInstance()

        if (!auth.isAuthenticated) {
          next()
          return
        }

        const publicPages = [
          'licenseAgreement',
          'privacy',
          'avv',
          'legal',
          'company-creation',
          'logout',
        ]
        if (publicPages.includes(to.name)) {
          next()
          return
        }

        const featureConfig = getInstance()
        await featureConfig.loadAllFeaturesForCompany(auth.user.companyId, {
          apolloProvider,
          reload: false,
        })

        const avvFeature = featureConfig.featuresBySlug[featureNames.AVV]
        const licenseAgreementFeature = featureConfig.featuresBySlug[featureNames.LICENSE_AGREEMENT]

        if (licenseAgreementFeature?.isActive && licenseAgreementFeature?.config?.hasAvv) {
          next()
          return
        }

        if (avvFeature?.isActive) {
          const apolloClient = apolloProvider.defaultClient
          const {
            data: { company },
          } = await apolloClient.query({
            query: AVV,
            variables: { id: auth.user.companyId },
          })

          if (!company) {
            throw new Error('Company not found')
          }

          if (company.avv?.acceptedByUserId && !company.avv?.acceptedAt) {
            next()
            return
          }

          const avvAcceptedAt = new Date(company.avv?.acceptedAt).getTime()
          const lastModifiedAt = new Date(avvFeature.config.lastModifiedAt).getTime()
          const isAvvOutdated = avvAcceptedAt < lastModifiedAt

          if (!company.avv?.acceptedAt || isAvvOutdated) {
            next({ name: 'avv', replace: true })
            return
          }
        }

        next()
      } catch (error) {
        console.error('AVV guard error:', error)
        next(false)
      }
    })
  }
}
