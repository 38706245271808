<template>
  <v-list class="mt-0 primary-navigation">
    <v-list-item
      v-for="item in navItems"
      :key="item.to"
      :to="item.to"
      class="rounded-lg nav-item"
      active-class="rounded-lg grey-lighten-3 font-weight-bold"
      :active="isRouteActive(item.to)"
      color="primary"
      @click="handleItemClick(item)"
    >
      <template #prepend>
        <v-badge
          v-if="item.badge && item.badge.condition({ showRegeneration })"
          bordered
          location="bottom"
          :color="item.badge.color"
          dot
          offset-x="10"
          offset-y="10"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-badge>
        <v-badge
          v-else-if="item.isNew && showIsNew"
          content="NEW"
          color="orange"
          offset-x="10"
          offset-y="10"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-badge>
        <v-icon v-else>
          {{ item.icon }}
        </v-icon>
      </template>

      <v-list-item>
        <v-list-item-title>
          {{ $t(item.label) }}
          <v-badge
            v-if="item.isBeta"
            content="BETA"
            color="orange"
            class="ml-2"
          />
        </v-list-item-title>
      </v-list-item>
    </v-list-item>
  </v-list>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { useRoute } from 'vue-router'

import brandingMixin from '@/mixins/branding'
import { NavItem } from '@/modules/navigation/models/Navitem'

defineProps<{
  navItems: PropType<NavItem[]>
  showRegeneration?: PropType<boolean>
}>()

const emit = defineEmits<{
  'navigation-click': () => PropType<void>
}>()

const { isPlatformMarkero } = brandingMixin.computed
const route = useRoute()

const showIsNew = computed(() => {
  return !isPlatformMarkero
})

const isRouteActive = (itemPath: string) => {
  if (!itemPath) return false

  const currentPath = route.path
  const normalizedItemPath = itemPath.replace(/\/$/, '')
  const normalizedCurrentPath = currentPath.replace(/\/$/, '')
  return (
    normalizedCurrentPath === normalizedItemPath ||
    normalizedCurrentPath.startsWith(normalizedItemPath + '/')
  )
}

const handleItemClick = (item: any) => {
  emit('navigation-click', item.trackingLabel)
}
</script>

<style scoped>
.primary-navigation {
  flex: 1;
  overflow-y: auto !important;
  overflow-x: hidden;
}

.nav-item {
  padding-left: 10px !important;
}
</style>
