import * as Sentry from '@sentry/browser'

let subscriptionJsPromise = null

/**
 * Initializes the SubscriptionJS library.
 * Uses the global SubscriptionJS instance if it is already available.
 *
 * @returns {Promise<Object>} A promise that resolves with the SubscriptionJS instance.
 */
export async function getSubscriptionJS() {
  if (subscriptionJsPromise) {
    return subscriptionJsPromise
  }

  subscriptionJsPromise = new Promise((resolve, reject) => {
    if (window.SubscriptionJS) {
      resolve(window.SubscriptionJS)
    } else {
      loadSubscriptionJSScript()
        .then(() => {
          if (window.SubscriptionJS) {
            resolve(window.SubscriptionJS)
          } else {
            reject(new Error('Failed to load SubscriptionJS'))
          }
        })
        .catch((error) => {
          Sentry.captureException(error)
          reject(new Error('Failed to load SubscriptionJS script: ' + error.message))
        })
    }
  })

  return subscriptionJsPromise
}

/**
 * Loads the SubscriptionJS script.
 *
 * @returns {Promise<void>} A promise that resolves when the script is loaded.
 */
function loadSubscriptionJSScript() {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.setAttribute('src', import.meta.env.VITE_APP_BILLWERK_SDK_ENDPOINT)
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('async', true)
    script.onload = resolve
    script.onerror = reject
    document.body.appendChild(script)
  })
}
