<template>
  <BaseHeader
    :title="baseHeaderTitle"
    :style-variant="1"
    wrapperClass="header mt-md-4"
  >
    <template v-slot:design>
      <HeaderDesign />
    </template>
    <template v-slot:desc>
      <p>
        {{ $t('dashboard.baseHeader.text') }}
      </p>
    </template>
  </BaseHeader>
</template>
<script setup lang="ts">
import { computed, getCurrentInstance } from 'vue'
import { useI18n } from 'vue-i18n'

import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from '@/modules/dashboard/HeaderDesign.vue'

const { t } = useI18n()
const { appContext } = getCurrentInstance()!
const $auth = appContext.config.globalProperties.$auth

const baseHeaderTitle = computed(() => {
  return $auth.user.given_name
    ? `${t('dashboard.baseHeader.title')}, ${$auth.user.given_name}!`
    : `${t('dashboard.baseHeader.title')}`
})
</script>
