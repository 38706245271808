<template>
  <v-card
    v-if="!verified"
    border
  >
    <v-card-title>
      <v-row>
        <v-col
          cols="10"
          style="text-wrap: auto"
        >
          <v-icon
            class="mr-2"
            color="black"
          >
            mdi-email-outline
          </v-icon>
          <span class="break-text">
            {{ $t('user-mail-verification.title') }}
          </span>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-end"
        >
          <v-spacer />
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }">
              <!-- TODO: check unsupported prop: fab -->
              <v-btn
                variant="text"
                size="small"
                :loading="isLoading"
                :disabled="isLoading"
                v-bind="props"
                @click="checkStatus"
              >
                <v-icon alt="Aktualisieren"> mdi-reload </v-icon>
              </v-btn>
            </template>
            <span>Aktualisieren</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          {{
            $t('user-mail-verification.body-texts.0', {
              email: email,
              platform: readablePlatformName,
            })
          }}<br />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-end"
        >
          <v-btn
            :color="isPlatformMarkero ? 'primary' : 'success'"
            variant="text"
            :loading="isLoading"
            :disabled="isLoading"
            @click="sendVerifyEmailMail"
          >
            {{ $t('buttons.user-mail-verification.cta') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import eventBus, { eventNames } from '@/lib/eventBus'
import brandingMixin from '@/mixins/branding'

import SEND_VERIFY_EMAIL_MAIL from './SendVerifyEmailMail.gql'

export default {
  mixins: [brandingMixin],
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    email() {
      return this.$auth.user.email
    },
    verified() {
      return this.$auth.user.email_verified
    },
  },
  methods: {
    async checkStatus() {
      await this.$auth.getTokenSilently({
        cacheMode: 'off',
      })
    },
    async sendVerifyEmailMail() {
      this.isLoading = true
      try {
        await this.$apollo.mutate({ mutation: SEND_VERIFY_EMAIL_MAIL })
        eventBus.$emit(eventNames.SHOW_SNACKBAR, {
          color: 'success',
          text: this.$t('alerts.user-mail-verification.success', { email: this.email }),
        })
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, {
          color: 'error',
          text: this.$t('alerts.user-mail-verification.error'),
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style scoped>
.break-text {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: calc(100% - 30px);
}
</style>
