<template>
  <v-navigation-drawer
    v-if="$auth.isAuthenticated"
    ref="drawerRef"
    :model-value="showDrawer"
    class="px-2 pb-3 navigation-drawer-height"
    rail-width="60"
    :rail="display.lgAndUp && isDrawerCollapsible"
    :width="270"
    :location="display.mdAndDown ? 'right' : undefined"
    :expand-on-hover="true"
    @update:rail="handleDrawerRail"
    @mouseenter="handleMouseEvent(true)"
    @mouseleave="handleMouseEvent(false)"
  >
    <!-- Company Logo -->
    <template v-slot:prepend>
      <CompanyLogo
        :is-drawer-open="isDrawerOpen"
        @track-click="trackClick('Logo')"
        @go-home="goHome"
        class="company-logo"
      />
    </template>

    <!-- User Section -->
    <!-- User Menu Section -->
    <FeatureFlag
      :feature-name="featureNames.USAGE_COUNTER"
      @feature-change="onUsageCounterFeatureChange"
    >
      <v-divider />
      <v-list-item class="px-0">
        <MainNavigation
          :is-drawer-open="isDrawerOpen"
          :user-picture="$auth.user.picture"
          :user-name="$auth.user.given_name + ' ' + $auth.user.family_name"
          :company-name="company?.name"
          @track-click="trackClick($event)"
          @logout="logout"
        />
      </v-list-item>
      <v-divider />
    </FeatureFlag>
    <div class="navigation-container">
      <!-- Primary Navigation -->
      <PrimaryNavigationItems
        :nav-items="navItems"
        :show-regeneration="showRegeneration"
        @navigation-click="handleItemClick($event)"
      />
      <v-divider />
      <!-- Secondary Navigation -->
      <SecondaryNavigationItems
        :nav-items="filteredNavItemsBottimmo"
        :is-drawer-open="isDrawerOpen"
        :unread-news-count="unreadNewsCount"
        :show-regeneration="showRegeneration"
        @navigation-click="handleItemClick($event)"
      />
    </div>

    <!-- User Area -->
    <template v-slot:append>
      <FeatureFlag :feature-name="featureNames.USAGE_COUNTER">
        <template v-slot="props">
          <v-list-item
            v-if="isDrawerOpen && company"
            class="usage-counter"
            @mounted="isUsageCounterActive = true"
          >
            <UsageCounter
              :product-type="productType"
              v-bind="props"
            />
          </v-list-item>
        </template>
        <template #fallback>
          <!-- User Area -->
          <UpgradeButton
            v-if="isEntryPlan"
            style="width: 100%"
            variant="elevated"
            class="my-4"
            :label="$t('navigation.labels.upgrade')"
            :show-label="showLabel"
            @track-click="$tracking.event('Sidebar', 'Clicked', 'Upgrade')"
          />

          <!-- TODO: check unsupported prop: offset-y -->
          <FooterNavigation
            :is-drawer-open="isDrawerOpen"
            :product-type="productType"
            :company-name="company?.name"
            @track-click="trackClick($event)"
            @mounted="isUsageCounterActive = false"
            @logout="logout"
          />
        </template>
      </FeatureFlag>
    </template>
  </v-navigation-drawer>
</template>
<script lang="ts" setup>
import { useQuery } from '@vue/apollo-composable'
import { computed, getCurrentInstance, ref, watch } from 'vue'

import FeatureFlag from '@/components/FeatureFlag.vue'
import UsageCounter from '@/components/UsageCounter.vue'
import featureNames from '@/lib/featureNames.js'
import featureMixin from '@/mixins/feature.js'
import CompanyLogo from '@/modules/navigation/CompanyLogo.vue'
import FooterNavigation from '@/modules/navigation/FooterNavigation.vue'
import MainNavigation from '@/modules/navigation/MainNavigation.vue'
import { NavItem } from '@/modules/navigation/models/Navitem'
import PrimaryNavigationItems from '@/modules/navigation/PrimaryNavigationItems.vue'
import SecondaryNavigationItems from '@/modules/navigation/SecondaryNavigationItems.vue'
import UpgradeButton from '@/modules/navigation/UpgradeButton.vue'
import PENDING_CHANGES_FOR_REGENERATION from '@/queries/PendingChangesForRegeneration.gql'

const props = defineProps<{
  display: Record<string, boolean>
  navItems: NavItem[]
  filteredNavItemsBottimmo: NavItem[]
  unreadNewsCount: number
  company?: { id: string; productType?: string }
  isDrawerCollapsible: boolean
  showDrawer: boolean
  isEntryPlan: boolean
}>()

const emit = defineEmits<{
  (e: 'navigation-click', value: string): void
  (e: 'track-click', value: string): void
  (e: 'logout'): void
  (e: 'go-home'): void
}>()

const { appContext } = getCurrentInstance()!
const $tracking = appContext.config.globalProperties.$tracking
const $auth = appContext.config.globalProperties.$auth
const isDrawerOpen = ref(!props.isDrawerCollapsible)
const isUsageCounterActive = ref(false)
const showLabel = ref<boolean>()
const drawerRef = ref(null)

const companyId = computed(() => props.company?.id)

const variables = computed(() => ({
  companyId: companyId.value || null,
}))

const { result } = useQuery(PENDING_CHANGES_FOR_REGENERATION, variables, {
  enabled: computed(() => !!companyId.value),
})

const pendingChangesForRegeneration = computed(() => {
  return result.value?.pendingChangesForRegeneration || {}
})

const showRegeneration = computed(() => {
  return (
    featureMixin.methods.isFeatureActive(featureNames.REGENERATION) &&
    Object.keys(pendingChangesForRegeneration.value).length > 0
  )
})

const productType = computed(() => props.company?.productType)

const handleItemClick = (label: string) => {
  emit('navigation-click', label)
}

const trackClick = (label: string) => {
  emit('track-click', label)
}

const goHome = () => {
  emit('go-home')
}

const logout = () => {
  emit('logout')
}

const onUsageCounterFeatureChange = (event: { enabled: boolean }) => {
  isUsageCounterActive.value = event.enabled
}

const handleMouseEvent = (show: boolean) => {
  if (props.isDrawerCollapsible) {
    showLabel.value = show
  }
}

const handleDrawerRail = (value: boolean) => {
  if (props.isDrawerCollapsible) {
    isDrawerOpen.value = !value
  }
}

watch(
  () => props.isDrawerCollapsible,
  (value) => {
    if (isDrawerOpen.value) return (showLabel.value = true)
    showLabel.value = !value
  },
  {
    immediate: true,
  }
)
</script>

<style scoped>
:deep(.v-navigation-drawer--mini-variant .v-list-item__spacer) {
  display: none;
}

:deep(.v-icon--size-default) {
  font-size: 20px;
}

:deep(.upgrade-button-label) {
  font-size: 16px;
}

:deep(.v-list-item__spacer) {
  display: none;
}

:deep(.list-item--variant-text) {
  color: unset;
}

.navigation-drawer-height ::v-deep(.v-navigation-drawer__content) {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.navigation-drawer-height ::v-deep(.v-list:last-child),
.navigation-drawer-height ::v-deep(.v-navigation-drawer__append) {
  margin-top: auto;
  margin-bottom: 0;
}

.usage-counter {
  padding: 0 !important;
}

::v-deep(.v-app-bar__nav-icon .v-icon) {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 16px;
}

.navigation-container {
  display: flex;
  flex-direction: column;
  height: v-bind("isUsageCounterActive ? 'calc(100% - 50px)' : '100%'");
}

.navigation-drawer-height ::v-deep(.v-navigation-drawer__content) {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.company-logo {
  min-height: 68px;
}

:deep(.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line) {
  padding-inline: 10px !important;
}
</style>
