/**
 * Deep merges two objects recursively.
 *
 * @param {Object} obj1 - The first object to merge.
 * @param {Object} obj2 - The second object to merge.
 * @returns {Object} - The merged object.
 */
export default function deepMergeObjects(obj1, obj2) {
  const result = {}

  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (
        typeof obj2[key] === 'object' &&
        Object.prototype.hasOwnProperty.call(obj1, key) &&
        typeof obj1[key] === 'object'
      ) {
        result[key] = deepMergeObjects(obj1[key], obj2[key])
      } else {
        result[key] = obj2[key]
      }
    }
  }

  for (const key in obj1) {
    if (
      Object.prototype.hasOwnProperty.call(obj1, key) &&
      !Object.prototype.hasOwnProperty.call(result, key)
    ) {
      if (typeof obj1[key] === 'object') {
        result[key] = deepMergeObjects(obj1[key], {})
      } else {
        result[key] = obj1[key]
      }
    }
  }

  return result
}
