import { defineComponent, getCurrentInstance, h, nextTick } from 'vue'

import bus, { eventNames } from '@/lib/eventBus'

/**
 * Vue 3 component that represents a feature.
 * @property {Object} props - The properties of the component.
 * @property {String} props.featureSlug - The slug of the feature. It is required.
 * @property {Boolean} props.useIsEnabled - Show not only when the feature isActive but already when isEnabled. Default is false.
 * @property {Boolean} props.useWrapper - Controls whether to wrap the content in a div element. Default is false.
 * @slot default - The default slot for the component. Rendered when the feature isActive.
 * @slot inactive - The slot for the component when the feature is inactive. Rendered when the feature isEnabled but not isActive.
 */
export default defineComponent({
  name: 'Feature',

  inheritAttrs: false,

  props: {
    featureSlug: {
      type: String,
      required: true,
    },
    useIsEnabled: {
      type: Boolean,
      default: false,
    },
    // Add a prop to control whether to use a wrapper
    useWrapper: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show: true,
      features: null,
    }
  },

  created() {
    // Get features from global properties as fallback
    const app = getCurrentInstance()
    this.features = app?.appContext?.config?.globalProperties?.$features

    if (!this.features) {
      console.warn('Features not found in global properties')
      return
    }

    // Using the event bus instead of emitter
    bus.$on(eventNames.CHANGE_FEATURE_CONFIG, this.handleFeatureChange)
  },

  unmounted() {
    bus.$off(eventNames.CHANGE_FEATURE_CONFIG, this.handleFeatureChange)
  },

  methods: {
    async handleFeatureChange() {
      this.show = false
      await nextTick()
      this.show = true
    },

    getFeature() {
      if (!this.features) return null

      try {
        const feature =
          typeof this.features.feature === 'function'
            ? this.features.feature(this.featureSlug)
            : Object.prototype.hasOwnProperty.call(this.features, this.featureSlug)
              ? this.features[this.featureSlug]
              : null

        return feature
      } catch (error) {
        console.error(`Error getting feature for slug ${this.featureSlug}:`, error)
        return null
      }
    },
  },

  render() {
    const feature = this.getFeature()

    if (!feature) {
      return null
    }

    const slotProps = {
      feature,
      ...this.$attrs,
    }

    // Function to get the appropriate slot content
    const getSlotContent = () => {
      if (this.show && (feature.isActive || (this.useIsEnabled && feature.isEnabled))) {
        return this.$slots.default?.(slotProps)
      } else if (feature.isEnabled && this.$slots.inactive) {
        // If the feature is enabled but not active, and the inactive slot is provided
        return this.$slots.inactive?.(slotProps)
      } else if (!feature.isEnabled && this.$slots.disabled) {
        // If the feature is disabled and the disabled slot is provided
        return this.$slots.disabled?.(slotProps)
      }
      return null
    }

    const slotContent = getSlotContent()

    // Return null if no content to render
    if (!slotContent) {
      return null
    }

    // If useWrapper is true, wrap in a div, otherwise return content directly
    return this.useWrapper ? h('div', { ...this.$attrs }, [slotContent]) : slotContent
  },
})
