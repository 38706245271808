import * as Sentry from '@sentry/browser'

import { formatCustomerData } from './customerChange'
import { getSubscriptionJS } from './getSubscriptionJS'

/**
 * Fetches a preview order using the signup service.
 *
 * @param {Object} params - The parameters for the preview order.
 * @param {Object} params.cart - The cart data.
 * @param {Object} params.customer - The customer data.
 *
 * @returns {Promise<Object>} - A promise that resolves with the preview order or rejects with an error.
 */
export async function getPreviewOrder({ cart, customer }) {
  if (!cart) throw new Error('Cart data is required')
  if (!customer) throw new Error('Customer data is required')

  try {
    const SubscriptionJS = await getSubscriptionJS()
    const signupInstance = new SubscriptionJS.Signup()
    return new Promise((resolve, reject) => {
      signupInstance.preview(
        cart,
        formatCustomerData(customer),
        (preview) => handlePreviewOrderCreated(preview, resolve),
        (error) => {
          Sentry.captureException(error)
          reject(error)
        }
      )
    })
  } catch (error) {
    return Promise.reject(new Error('Failed to initialize SubscriptionJS: ' + error.message))
  }
}

function handlePreviewOrderCreated(preview, resolve) {
  const { Order } = preview
  const previewOrder = {
    currency: Order.Currency,
    totalAfterVat: Order.TotalGross,
    payPeriod: Order.RecurringFee.FeePeriod.Quantity,
    coupon: Order.Coupon,
    isCouponValid: !Order.Coupon?.ErrorCode,
    couponRules: [!Order.Coupon?.ErrorCode || 'Invalid coupon code'],
    billingPeriods:
      Order.RecurringFee.LineItems.length > 0
        ? Order.RecurringFee.LineItems
        : [
            {
              Id: Order.PriceListId,
              TotalNet: Order.Total,
              TotalVat: Order.TotalVat,
              PeriodMultiplier: 1,
            },
          ],
  }
  resolve(previewOrder)
}
