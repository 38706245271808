import { createOrder } from './createOrder.js'
import { customerChange } from './customerChange.js'
import { finalize } from './finalize.js'
import { getContractDetails } from './getContractDetails.js'
import { getPaymentService } from './getPaymentService.js'
import { getPreviewOrder } from './getPreviewOrder.js'
import { getSubscriptionJS } from './getSubscriptionJS.js'
import { paymentChange } from './paymentChange.js'
import { paySignupInteractive } from './paySignupInteractive.js'
import { upgradePayInteractive } from './upgradePayInteractive.js'

export const billwerkApi = {
  finalize,
  createOrder,
  paymentChange,
  customerChange,
  getPreviewOrder,
  getPaymentService,
  getSubscriptionJS,
  getContractDetails,
  paySignupInteractive,
  upgradePayInteractive,
}
