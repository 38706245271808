query InvitationStatisticsForCompany {
  invitationStatisticsForCompany {
    conversions
    total
    invitationsPerLandingpage {
      conversions
      landingpageSlug
      landingpageName
      total
    }
  }
}
