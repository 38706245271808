<template>
  <v-card
    border
    :class="wrapperClass"
  >
    <slot name="button" />
    <div v-if="$vuetify.display.mdAndUp">
      <slot name="design" />
    </div>

    <div class="pa-4 pb-2">
      <v-row>
        <v-col
          cols="12"
          lg="9"
          class="ma-0 d-flex"
        >
          <div class="d-flex flex-column header-content">
            <div class="mb-2 d-flex mb-md-4">
              <v-icon v-if="icon">
                {{ icon }}
              </v-icon>
              <h2
                class="text-h6"
                :class="{ 'pl-2': icon }"
              >
                {{ title }}
              </h2>
            </div>
            <slot name="desc" />
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="3"
          class="ma-0 pa-0 d-flex header-content"
        >
          <slot name="charts" />
        </v-col>
      </v-row>
    </div>

    <div class="header-content">
      <slot name="content" />
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    wrapperClass: {
      type: String,
      default: 'mb-4 header mt-md-4',
    },
  },
}
</script>

<style scoped>
.header-content {
  z-index: 2;
  width: 100%;
}

.header {
  position: relative;
  overflow: hidden;
}
</style>

<style>
.banner-design-elements {
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
</style>
