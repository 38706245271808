<template>
  <div>
    <Leaf
      class="banner-design-elements"
      width="100"
      style="top: 10px; right: 100px"
      stroke
      :stroke-width="1"
    />

    <Leaf
      class="banner-design-elements"
      width="100"
      style="top: -50px; right: 30px; opacity: 0.4"
      stroke
      :stroke-width="1"
    />

    <Leaf
      class="banner-design-elements"
      width="50"
      style="top: 80px; right: 20px; opacity: 0.4"
      stroke
      :stroke-width="1"
    />
  </div>
</template>

<script>
import Leaf from '@/components/design-elements/leaf.vue'

export default {
  components: {
    Leaf,
  },
}
</script>
