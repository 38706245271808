<template>
  <component :is="component" />
</template>
<script>
import brandingMixin from '@/mixins/branding'

import LoginBottimmo from './bottimmo/index'
import LoginMarkero from './markero/index'
export default {
  mixins: [brandingMixin],
  computed: {
    component() {
      return this.isPlatformMarkero ? LoginMarkero : LoginBottimmo
    },
  },
}
</script>
