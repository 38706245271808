<template>
  <AuthView>
    <div class="flex-1">
      <h1 class="text-h4 font-weight-black">
        <span>{{ $t('login.title') }}</span>
      </h1>

      <p class="mb-10 text-grey text-grey-lighten-1">
        {{ $t('login.subtitle', { platformName: readablePlatformName }) }}
      </p>
      <LoginForm v-if="ssoProviders.length === 0" />

      <v-btn
        v-for="provider in ssoProviders"
        :key="provider.name"
        class="mt-4 w-full"
        color="primary"
        @click="loginWithRedirect"
      >
        {{ $t('login.sso', { provider: provider.name }) }}
      </v-btn>

      <div
        v-if="showSignup"
        class="text-center mt-4"
      >
        <span>{{ $t('login.register.question') }}</span>
        <a
          :style="{ color: 'primary' }"
          href="/auth/signup"
          class="ml-2 text-primary font-weight-bold hover"
          >{{ $t('login.register.link') }}</a
        >
      </div>

      <v-card
        v-if="showConsulting"
        border
        class="mt-8"
      >
        <v-card-text class="d-flex">
          <v-icon
            size="x-large"
            class="calendar-icon"
          >
            mdi-calendar-clock
          </v-icon>
          <div class="pa-4">
            <p class="mb-4">
              {{ $t('login.disclaimer') }}
            </p>
            <v-btn
              variant="outlined"
              center
              size="small"
              color="primary"
              class="px-4 text-center"
              href="https://termin.bottimmo.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('login.action') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </AuthView>
</template>

<script>
import { capitalizeFirstLetter } from '@/lib/capitalize'
import { consulting, signup } from '@/lib/features'
import { sso } from '@/lib/features'
import brandingMixin from '@/mixins/branding'
import feature from '@/mixins/feature'

import AuthView from '../../AuthView'
import LoginForm from '../LoginForm'

export default {
  components: { LoginForm, AuthView },
  mixins: [brandingMixin, feature],
  methods: {
    capitalizeFirstLetter,
    loginWithRedirect() {
      this.$auth.loginWithRedirect()
    },
  },
  computed: {
    ssoProviders() {
      // multiple SSO providers won't work for now, because the client_id is used from the VITE_AUTH0_CLIENT_ID
      return sso.isActive ? sso.config.providers.filter(({ isActive }) => isActive) : []
    },
    showConsulting() {
      return consulting?.isActive
    },
    showSignup() {
      return signup?.isActive
    },
  },
}
</script>
<style scoped>
/* Hover Styles */
.hover:hover {
  cursor: pointer;
}
.calendar-icon {
  align-self: center;
  color: rgba(var(--v-theme-on-surface), 0.54);
}
</style>
