<template>
  <v-list-item
    :id="news.trackingLabel.toLowerCase()"
    :to="news.to"
    :href="news.href"
    :target="news.target"
    :rel="news.rel"
    @click="handleNewsClick"
    width="56"
  >
    <template #prepend>
      <v-badge
        :content="unreadNewsCount"
        color="red"
        :model-value="unreadNewsCount > 0"
        offset-y="2"
      >
        <v-icon>{{ news.icon }}</v-icon>
      </v-badge>
    </template>
  </v-list-item>
</template>

<script lang="ts" setup>
type News = {
  trackingLabel: string
  to: string
  href: string
  target: string
  rel: string
  icon: string
}

const props = defineProps<{
  news: News
  unreadNewsCount: number
}>()

type Emits = {
  (e: 'news-click', value: string): void
}
const emit = defineEmits<Emits>()

const handleNewsClick = () => {
  emit('news-click', props.news.trackingLabel)
}
</script>

<style scoped>
:deep(.v-icon--size-default) {
  font-size: 28px;
}

.news-icon {
  margin: 8px 0;
  position: relative;
}

.unread-messages-count {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  z-index: 9;
}

.news-icon .unread-messages-count {
  top: -4px;
}
</style>
