// mixins/global.js
import date from '../lib/date'

export default {
  install(app) {
    app.mixin({
      ...date,
    })
  },
}
