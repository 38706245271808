import I18n from '../i18n'

const i18nInstance = I18n.getInstance()
const { t } = i18nInstance.global

// const URL = /^https:\/\/((www\.[-a-zA-Z0-9@:%_+~#=]{1,256}\.[-a-zA-Z0-9()@:%_+.~#?&//=]{2,256})|((?!www)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[-a-zA-Z0-9()@:%_+.~#?&//=]{2,256}))$/
const EMAIL =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
const NEW_URL = /^(https?:\/\/)?([\da-zA-Z\\.-]+)\.([a-z]{2,6})([\\/\w\\.-]*)*\/?/

export const empty = (value) => value === null || value === undefined || value === ''

export const betweenRule = (value, min, max) =>
  (value >= min && value <= max) || `${t('valuation.valuation-form.between')} ${min} - ${max}`
export const length =
  ({ min = 0, max = '' }) =>
  (value) =>
    empty(value) ||
    new RegExp(`^.{${min},${max}}$`).test(value) ||
    `Die Länge muss zwischen ${min} und ${max === '' ? 'unbegrenzt' : max} Zeichen sein`
export const lengthWithEmptySpace =
  ({ min = 0, max = '' }) =>
  (value) =>
    new RegExp(`^.{${min},${max}}$`).test(value.replace(/\s+/g, ' ')) ||
    `Die Länge muss zwischen ${min} und ${max === '' ? 'unbegrenzt' : max} Zeichen sein`
export const zip = (value) =>
  empty(value) || /^\d+$/.test(value) || t('labels.numbers-only-validation')
export const phoneStripped = (value) =>
  empty(value) || !/^(\+|00)/.test(value) || 'Nummer darf keine Ländervorwahl enthalten'
export const email = (value) =>
  empty(value) || EMAIL.test(value) || t('labels.email-invalid-validation')
export const url = (value) => empty(value) || NEW_URL.test(value) || 'Bitte gültige URL eintragen'
export const required = (value) => !!value || value === 0 || t('labels.required')
export const halfNumber = (value) =>
  value ? value % 0.5 === 0 || 'Nur Zahlen in 0.5 Schritten' : true
export const integer = (value) => (value ? value % 1 === 0 || 'Nur ganze Zahlen' : true)
export const optionalForOnboarding = (isOnboarding) => (value) =>
  !!value || isOnboarding || t('labels.required')
export const optionalBooleanForOnboarding = (isOnboarding) => (value) =>
  value !== undefined || isOnboarding || t('labels.required')
export const positiveNumber = (value) => (value ? value > 0 || 'Nur positive Zahlen' : true)
export const matchPattern = (pattern, errorMessage) => (value) =>
  new RegExp(pattern).test(value) ||
  errorMessage ||
  `Bitte folgendes Eingabemuster beachten: ${pattern}`

export const isValidUrl = (url) => {
  return NEW_URL.test(url)
}

/**
 * Just test cases which are not covered by libphonenumber-js.
 * copy from service app/lib/phoneNumber.js
 * - Repeating digits: e.g. 11111 or 88888888
 * - Increasing digits: 12345
 *
 * @param {string} phoneNumber
 * @returns {boolean}
 */
export const hasNumericalSequence = (phoneNumber) => {
  const hasRepeatingDigits = /([0-9])\1{4}/.test(phoneNumber) // at least 5 in a row
  const hasIncreasingDigits = phoneNumber.includes('12345')

  return hasRepeatingDigits || hasIncreasingDigits
}

/**
 * @deprecated
 */
export const fullNumber = integer
