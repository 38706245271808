<template>
  <div class="d-flex h-full">
    <div
      style="position: relative"
      class="mr-8"
    >
      <v-icon
        :class="'mr-2 ' + styleIcon"
        style="position: absolute"
        :style="{
          left: isBigLogo ? '-25px' : isMediumLogo ? '-3px' : '0px',
          top: isBigLogo ? '-18px' : isMediumLogo ? '0px' : '0px',
        }"
        :size="iconSize"
      >
        {{ icon }}
      </v-icon>
      <span
        style="position: absolute"
        :style="{
          marginTop: isBigLogo ? '2px' : isMediumLogo ? '1px' : '2px',
          left: isBigLogo ? '-1px' : isMediumLogo ? '1px' : '0px',
        }"
        :class="{
          [`ml-2 text-white font-weight-bold ${styleLabel}`]: true,
          'text-body-2': isSmallLogo,
          'text-h6': isMediumLogo,
          'text-h2': isBigLogo,
        }"
        >{{ labelShort }}</span
      >
    </div>
    <span v-if="showLabel"
      ><b>{{ label }}</b></span
    >
  </div>
</template>

<script>
import { ProductType } from '@/lib/productTypes'

const packageInfo = {
  [ProductType.LIGHT]: {
    icon: 'mdi-decagram-outline',
    color: 'gray',
    labelShort: 'L',
    styleLabel: 'text-grey',
    styleIcon: 'text-grey',
    label: 'Light',
  },
  [ProductType.PIONEER]: {
    icon: 'mdi-decagram-outline',
    color: 'gray',
    labelShort: 'P',
    styleLabel: 'text-grey',
    styleIcon: 'text-grey',
    label: 'Pioneer',
  },
  [ProductType.BASIC]: {
    icon: 'mdi-decagram-outline',
    color: 'primary-gradient-background',
    labelShort: 'B',
    styleLabel: 'static-gradient-background background-clip-icon',
    styleIcon: 'static-gradient-background background-clip-icon',
    label: 'Basic',
  },
  [ProductType.EXPERT]: {
    icon: 'mdi-decagram',
    color: 'primary-gradient-background',
    labelShort: 'E',
    styleLabel: 'text-white',
    styleIcon: 'static-gradient-background background-clip-icon',
    label: 'Expert',
  },
  [ProductType.BEGINNER]: {
    icon: 'mdi-decagram-outline',
    color: 'gray',
    labelShort: 'B',
    styleLabel: 'text-grey',
    styleIcon: 'text-grey',
    label: 'Beginner',
  },
  [ProductType.ADVANCED]: {
    icon: 'mdi-decagram',
    color: 'primary-gradient-background',
    labelShort: 'A',
    styleLabel: 'text-white',
    styleIcon: 'static-gradient-background background-clip-icon',
    label: 'Advanced',
  },
  [ProductType.ADVANCED_TRIAL]: {
    icon: 'mdi-decagram',
    color: 'primary-gradient-background',
    labelShort: 'A',
    styleLabel: 'text-white',
    styleIcon: 'static-gradient-background background-clip-icon',
    label: 'Advanced (Trial)',
  },
  [ProductType.BEGINNER_TRIAL]: {
    icon: 'mdi-decagram-outline',
    color: 'gray',
    labelShort: 'B',
    styleLabel: 'text-grey',
    styleIcon: 'text-grey',
    label: 'Beginner (Advanced Trial)',
  },
}
export default {
  props: {
    currentPackage: {
      type: String,
      default: ProductType.LIGHT,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    isBigLogo() {
      return this.size === 'large'
    },
    isMediumLogo() {
      return this.size === 'medium'
    },
    isSmallLogo() {
      return this.size === 'small'
    },
    iconSize() {
      return this.isBigLogo ? 100 : this.isMediumLogo ? 36 : 24
    },
    currentPackageInfo() {
      return packageInfo[this.currentPackage] || {}
    },
    styleIcon() {
      return this.currentPackageInfo.styleIcon
    },
    styleLabel() {
      return this.currentPackageInfo.styleLabel
    },
    icon() {
      return this.currentPackageInfo.icon
    },
    color() {
      return this.currentPackageInfo.color
    },
    label() {
      return this.currentPackageInfo.label
    },
    labelShort() {
      return this.currentPackageInfo.labelShort
    },
  },
}
</script>
