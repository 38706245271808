<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="auto"
        class="mt-8 content"
      >
        <confirmation
          v-if="submittedEmail"
          :email="submittedEmail"
        />
        <e-mail-form
          v-else
          @submitted="handleSubmitted"
        />
        <v-card
          border
          class="mt-2"
        >
          <v-card-title>
            {{ $t('reset-password.video.title') }}
          </v-card-title>
          <v-card-text>
            <InstructionVideo />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InstructionVideo from '@/modules/auth/PasswordResetRequest/InstructionVideo.vue'

import Confirmation from '../bottimmo/Confirmation'
import EMailForm from '../EMailForm'

export default {
  components: {
    InstructionVideo,
    EMailForm,
    Confirmation,
  },
  data: () => ({
    submittedEmail: null,
  }),
  methods: {
    handleSubmitted({ email }) {
      this.submittedEmail = email
    },
  },
}
</script>

<style scoped>
.content {
  width: 450px;
}
</style>
