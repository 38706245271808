import * as Sentry from '@sentry/browser'

import { getPaymentService } from './getPaymentService'
import { getSubscriptionJS } from './getSubscriptionJS'

/**
 * Handles the interactive signup payment process.
 *
 * @param {Object} params - The parameters for the payment process.
 * @param {Object} params.order - The order details.
 * @param {string} [params.paymentMethod='None:None'] - The payment method.
 * @param {string|undefined} params.providerReturnUrl - The provider return URL (optional).
 * @returns {Promise<Object>} A promise that resolves with the payment data or rejects with an error.
 */
export async function paySignupInteractive({
  order,
  paymentMethod = 'None:None',
  providerReturnUrl,
}) {
  if (!order) throw new Error('Order is required')

  try {
    const SubscriptionJS = await getSubscriptionJS()
    const paymentService = await getPaymentService(providerReturnUrl)
    const paymentData = { bearer: paymentMethod }
    const signupService = new SubscriptionJS.Signup()

    return new Promise((resolve, reject) => {
      signupService.paySignupInteractive(paymentService, paymentData, order, resolve, (error) => {
        Sentry.captureException(error)
        reject(error)
      })
    })
  } catch (error) {
    throw new Error('Payment service initialization error: ' + error.message)
  }
}
