<template>
  <div>
    <slot
      v-if="isFeatureEnabled"
      v-bind="slotProps"
    />
    <slot
      v-else
      name="fallback"
    />
  </div>
</template>

<script>
import { debounce } from '@/lib/debounce'
import { transformToKebabCase } from '@/lib/kebabCase'
import { sanitizeFeatureValue } from '@/lib/sanitizeFeatureValue'

export default {
  name: 'FeatureFlag',
  // Allow non-prop attributes to pass through
  inheritAttrs: false,
  props: {
    // Name of the feature flag to check in URL
    featureName: {
      type: String,
      required: true,
    },
    // Optional default value if feature flag is not in URL or localStorage
    defaultValue: {
      type: Boolean,
      default: false,
    },
    // Optional prop names to extract from URL when feature is enabled
    urlParamName: {
      type: String,
      default: null,
    },
  },
  emits: ['feature-change'],

  data() {
    return {
      isFeatureEnabled: false,
      slotProps: {},
      debouncedCheckFeatureFlag: null,
    }
  },

  watch: {
    isFeatureEnabled: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit('feature-change', {
            featureName: this.featureName,
            enabled: newValue,
            props: this.slotProps,
          })
        }
      },
    },
  },

  created() {
    // Initialize debounced check function
    this.debouncedCheckFeatureFlag = debounce(this.checkFeatureFlag, 250)
  },

  mounted() {
    // Only add listeners if we're in a browser environment
    if (typeof window !== 'undefined') {
      this.checkFeatureFlag()
      window.addEventListener('popstate', this.debouncedCheckFeatureFlag)
    }
  },

  beforeUnmount() {
    // Clean up listeners and debounced function
    if (typeof window !== 'undefined') {
      window.removeEventListener('popstate', this.debouncedCheckFeatureFlag)
      this.debouncedCheckFeatureFlag.cancel()
    }
  },

  methods: {
    getPropsStorageKey() {
      // Get localStorage key for props with sanitization
      return `feature_${sanitizeFeatureValue(this.featureName)}_props`
    },

    safelyStoreFeature(name, value) {
      // Sanitize feature values to prevent XSS and injection attacks
      try {
        const key = `feature_${sanitizeFeatureValue(name)}`
        localStorage.setItem(key, String(value))
        // Emit the change event when storing a new value
        this.$emit('feature-change', {
          featureName: name,
          enabled: value === 'true',
          props: this.slotProps,
        })
      } catch (e) {
        console.warn('Failed to store feature flag:', e)
      }
    },

    storeProps(props) {
      try {
        const sanitizedProps = Object.entries(props).reduce((acc, [key, value]) => {
          if (typeof key === 'string') {
            acc[sanitizeFeatureValue(key)] = value
          }
          return acc
        }, {})
        localStorage.setItem(this.getPropsStorageKey(), JSON.stringify(sanitizedProps))
      } catch (e) {
        console.warn('Failed to store feature flag props in localStorage:', e)
      }
    },

    getStoredProps() {
      try {
        const stored = localStorage.getItem(this.getPropsStorageKey())
        return stored ? JSON.parse(stored) : {}
      } catch (e) {
        console.warn('Failed to retrieve feature flag props from localStorage:', e)
        return {}
      }
    },

    parseUrlValue(value) {
      // First decode the URL encoded value
      const decodedValue = decodeURIComponent(value)

      // Handle boolean values
      if (decodedValue.toLowerCase() === 'true') return true
      if (decodedValue.toLowerCase() === 'false') return false

      // Try parsing as JSON - handle both single and double quotes
      try {
        // Replace single quotes with double quotes for valid JSON
        const normalizedValue = decodedValue.replace(/'/g, '"')
        return JSON.parse(normalizedValue)
      } catch (e) {
        // If JSON parsing fails, return the decoded value
        return decodedValue
      }
    },

    checkFeatureFlag() {
      if (typeof this.featureName === 'boolean') {
        this.isFeatureEnabled = this.featureName
        return
      }

      try {
        const urlParams = new URLSearchParams(window.location.search)
        const paramName = this.urlParamName || transformToKebabCase(this.featureName)
        const featureValue = urlParams.get(paramName)

        if (featureValue !== null) {
          const sanitizedValue = sanitizeFeatureValue(featureValue)
          const isEnabled = sanitizedValue.toLowerCase() === 'true'
          this.isFeatureEnabled = isEnabled

          this.safelyStoreFeature(this.featureName, isEnabled)

          if (isEnabled) {
            this.extractUrlProps()
          }
        } else {
          const storedValue = localStorage.getItem(
            `feature_${sanitizeFeatureValue(this.featureName)}`
          )
          this.isFeatureEnabled = storedValue !== null ? storedValue === 'true' : this.defaultValue

          if (this.isFeatureEnabled) {
            this.slotProps = this.getStoredProps()
          }
        }
      } catch (error) {
        console.error('Error checking feature flag:', error)
        this.isFeatureEnabled = this.defaultValue
        this.slotProps = {}
      }
    },

    extractUrlProps() {
      try {
        const urlParams = new URLSearchParams(window.location.search)
        const extractedProps = {}

        // Process all URL parameters
        for (const [key, value] of urlParams.entries()) {
          // Convert kebab-case to camelCase
          const camelKey = key.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase())

          // Skip the feature flag parameter itself
          if (camelKey === this.featureName || key === this.urlParamName) {
            continue
          }

          // Parse and store the value
          extractedProps[camelKey] = this.parseUrlValue(value)
        }

        // Store props and update component state
        this.slotProps = extractedProps
        this.storeProps(extractedProps)
      } catch (error) {
        console.error('Error extracting URL props:', error)
        this.slotProps = {}
      }
    },
  },
}
</script>
