<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600"
  >
    <template v-slot:activator="{ props }">
      <v-btn
        class="mx-2 feedback-button"
        color="primary"
        size="small"
        v-bind="props"
      >
        Support
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>Support</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <!-- TODO: check unsupported prop: dark -->
            <v-btn
              icon
              @click="handleCancel"
            >
              <v-icon size="large"> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <feedback-form
          :classification-list="config.classifications"
          @feddbackSubmitted="handleFormSubmit"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          variant="text"
          @click="handleCancel"
        >
          abbrechen
        </v-btn>
        <v-btn
          variant="text"
          color="primary"
          @click="submitFeedbackForm"
        >
          absenden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import bus, { eventNames } from '@/lib/eventBus'

import FeedbackForm from './FeedbackForm.vue'
export default {
  components: {
    FeedbackForm,
  },
  props: {
    config: {
      type: Object,
      default: () => ({ classifications: [] }),
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  mounted() {
    bus.$on(eventNames.OPEN_FEEDBACK_FORM, this.openDialog)
  },
  methods: {
    handleCancel() {
      bus.$emit(eventNames.RESET_FEEDBACK_FORM)
      this.dialog = false
    },
    submitFeedbackForm() {
      bus.$emit(eventNames.SUBMIT_FEEDBACK_FORM)
    },
    handleFormSubmit() {
      bus.$emit(eventNames.SHOW_SNACKBAR, {
        color: 'success',
        text: 'Supportanfrage wurde versendet',
      })
      this.handleCancel()
    },
    openDialog() {
      this.dialog = true
    },
  },
}
</script>

<style>
.feedback-button {
  transform: rotate(-90deg);
  position: fixed;
  right: -44px;
  bottom: 50vh;
  z-index: 1;
}
</style>
