import { createAuth0Client } from '@auth0/auth0-spa-js'
import * as Sentry from '@sentry/vue'
import { WebAuth } from 'auth0-js'
import { reactive } from 'vue'

import bus, { eventNames } from '@/lib/eventBus'
import { sso } from '@/lib/features'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

const isRedirectedAfterAuthentication = () =>
  window.location.search.includes('code=') && window.location.search.includes('state=')

function getLocationBeforeLogin() {
  const location = sessionStorage.getItem('locationBeforeLogin')
  sessionStorage.removeItem('locationBeforeLogin')
  return location
}

function parseJwt(token) {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    return JSON.parse(window.atob(base64))
  } catch (e) {
    console.error('Error parsing JWT:', e)
    return null
  }
}

function isTokenExpired(token) {
  const claims = parseJwt(token)
  if (!claims?.exp) return true
  // exp is in seconds, Date.now() is in milliseconds
  return claims.exp * 1000 <= Date.now()
}

let instance

export const getInstance = () => instance

export const createAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  domain,
  clientId,
  audience,
  databaseConnection,
}) => {
  if (instance) return instance

  instance = reactive({
    loading: true,
    isAuthenticated: false,
    user: {},
    auth0Client: null,
    webAuth: null,
    error: null,
    client: null,
    customToken: null,

    async prepareUser(user) {
      if (!user) return user
      const metaClaim = user[import.meta.env.VITE_APP_AUTH0_META_CLAIM] || {}
      return {
        ...user,
        ...metaClaim,
      }
    },

    async onCreated() {
      this.auth0Client = await createAuth0Client({
        domain,
        authorizationParams: {
          audience,
          redirect_uri: redirectUri,
        },
        clientId,
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
      })

      this.webAuth = new WebAuth({
        domain,
        audience,
        clientID: clientId,
        redirectUri,
        responseType: 'code',
      })

      try {
        if (isRedirectedAfterAuthentication()) {
          await this.auth0Client.getTokenSilently()
          onRedirectCallback(getLocationBeforeLogin())
        }
      } catch (err) {
        this.error = err
      } finally {
        if (await this.isSso()) {
          const { user } = await this.getSsoToken()
          this.user = await this.prepareUser(user)
        } else {
          this.user = await this.prepareUser(await this.auth0Client.getUser())
        }
        this.isAuthenticated = !!this.user
        this.loading = false

        if (this.isAuthenticated) {
          bus.$emit(eventNames.USER_LOGGED_IN, this.user.companyId)
        }

        Sentry.configureScope((scope) => {
          if (this.user) {
            scope.setTag('companyId', this.user.companyId)
            scope.setUser({ id: this.user.userId || this.user.sub.split('|')?.[1] })
          } else {
            scope.clear()
          }
        })
      }
    },

    login(username, password) {
      return new Promise((resolve, reject) => {
        this.webAuth.login(
          {
            username,
            password,
            realm: databaseConnection,
          },
          (err) => (err ? reject(err) : resolve())
        )
      })
    },
    async logout(options) {
      const appLocale = localStorage.getItem('appLocale')
      localStorage.clear()

      if (appLocale !== null) {
        localStorage.setItem('appLocale', appLocale)
      }

      return this.auth0Client.logout(options)
    },

    loginWithRedirect(options) {
      return this.auth0Client.loginWithRedirect(options)
    },

    changePassword({ email }) {
      return new Promise((resolve, reject) => {
        this.webAuth.changePassword(
          {
            connection: databaseConnection,
            email,
          },
          (err, res) => (err ? reject(err) : resolve(res))
        )
      })
    },

    getIdTokenClaims(options) {
      return this.auth0Client.getIdTokenClaims(options)
    },

    async isSso() {
      try {
        const claims = await this.auth0Client.getIdTokenClaims()
        return sso.isActive && sso.config.providers.some(({ issuer }) => claims?.iss === issuer)
      } catch (error) {
        Sentry.captureException(error)
        return false
      }
    },

    async getSsoToken() {
      if (this.customToken && !isTokenExpired(this.customToken)) {
        return { token: this.customToken, user: this.user }
      }

      const response = await fetch(import.meta.env.VITE_APP_BOTTIMMO_SERVICE + '/sso/auth', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${await this.auth0Client.getTokenSilently()}`,
          'x-platform': import.meta.env.VITE_APP_PLATFORM,
        },
      })

      if (!response.ok) {
        Sentry.captureMessage('SSO token error', {
          extra: { status: response.status, body: await response.text() },
        })
        return { token: null, user: null }
      }

      const { token, user } = await response.json()
      this.customToken = token
      return { token, user }
    },

    async getTokenSilently(options) {
      let token, user
      if (await this.isSso()) {
        const response = await this.getSsoToken()
        token = response.token
        user = response.user
      } else {
        token = await this.auth0Client.getTokenSilently(options)
        user = await this.auth0Client.getUser()
      }
      this.user = await this.prepareUser(user)
      this.isAuthenticated = !!this.user
      return token
    },
  })

  return instance
}
