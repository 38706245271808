<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6143 9.95276L17.6116 9.95545C17.2369 10.3425 17.1236 10.913 17.3223 11.4139V11.4145C17.5961 12.1031 17.2721 12.8843 16.5919 13.1775C16.0967 13.3908 15.773 13.8747 15.7647 14.4138V14.4162C15.7533 15.1559 15.1562 15.753 14.4165 15.7644H14.4141C13.875 15.7728 13.3911 16.0967 13.1778 16.5916C12.8846 17.2721 12.1034 17.5958 11.4148 17.3223H11.4145C10.9139 17.1233 10.3431 17.2366 9.95605 17.6113L9.95336 17.614C9.42229 18.1287 8.57861 18.1287 8.04753 17.614L8.04484 17.6113C7.65782 17.2366 7.0873 17.1233 6.58641 17.322H6.58581C5.89724 17.5958 5.11602 17.2718 4.82284 16.5916C4.60946 16.0964 4.1256 15.7728 3.58646 15.7644H3.58407C2.84439 15.753 2.24727 15.1559 2.23592 14.4162V14.4138C2.22755 13.8747 1.90358 13.3908 1.40867 13.1775C0.728171 12.8843 0.404506 12.1031 0.677962 11.4145C0.876704 10.9133 0.763735 10.3425 0.388667 9.95575L0.385977 9.95306C-0.128659 9.42199 -0.128659 8.57831 0.385977 8.04724L0.388667 8.04455C0.763436 7.65752 0.876704 7.087 0.677962 6.58611V6.58552C0.404207 5.89694 0.728171 5.11573 1.40837 4.82254C1.90358 4.60916 2.22725 4.12531 2.23562 3.58616V3.58377C2.24697 2.8441 2.84409 2.24697 3.58377 2.23562H3.58616C4.1253 2.22725 4.60916 1.90329 4.82254 1.40837C5.11572 0.727872 5.89694 0.404207 6.58551 0.677664H6.58581C7.0864 0.876704 7.65722 0.763436 8.04425 0.388667L8.04694 0.385977C8.57801 -0.128659 9.42169 -0.128659 9.95276 0.385977L9.95545 0.388667C10.3425 0.763436 10.913 0.876704 11.4139 0.677962H11.4145C12.1031 0.404207 12.8843 0.728171 13.1775 1.40837C13.3908 1.90358 13.8747 2.22725 14.4138 2.23562H14.4162C15.1559 2.24697 15.753 2.8441 15.7644 3.58377V3.58616C15.7727 4.12531 16.0967 4.60916 16.5916 4.82254C17.2721 5.11573 17.5958 5.89694 17.3223 6.58552C17.1236 7.0867 17.2366 7.65752 17.6116 8.04425L17.6143 8.04694C18.129 8.57801 18.129 9.42169 17.6143 9.95276Z"
      fill="url(#badge-gold-outer)"
    />
    <path
      d="M8.98091 15.786C12.7285 15.786 15.7665 12.748 15.7665 9.00044C15.7665 5.25286 12.7285 2.21484 8.98091 2.21484C5.23333 2.21484 2.19531 5.25286 2.19531 9.00044C2.19531 12.748 5.23333 15.786 8.98091 15.786Z"
      fill="url(#badge-gold-middle)"
    />
    <path
      d="M8.99583 15.3627C12.51 15.3627 15.3588 12.5139 15.3588 8.99973C15.3588 5.48554 12.51 2.63672 8.99583 2.63672C5.48163 2.63672 2.63281 5.48554 2.63281 8.99973C2.63281 12.5139 5.48163 15.3627 8.99583 15.3627Z"
      fill="url(#badge-gold-inner)"
    />
    <defs>
      <linearGradient
        id="badge-gold-outer"
        x1="2.81242"
        y1="3.94061"
        x2="14.9342"
        y2="13.8517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EACF73" />
        <stop
          offset="1"
          stop-color="#B78013"
        />
      </linearGradient>
      <linearGradient
        id="badge-gold-middle"
        x1="4.2954"
        y1="5.16937"
        x2="13.474"
        y2="12.6743"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B78013" />
        <stop
          offset="1"
          stop-color="#EACF73"
        />
      </linearGradient>
      <linearGradient
        id="badge-gold-inner"
        x1="4.602"
        y1="5.40714"
        x2="13.2091"
        y2="12.4447"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EACF73" />
        <stop
          offset="1"
          stop-color="#B78013"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
import svg from '@/mixins/svg'
export default {
  mixins: [svg],
}
</script>
