<template>
  <layout :style="cssProps">
    <router-view />
    <ProductFruits />
    <appcues />
    <base-snackbar />
    <animation />
  </layout>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import BaseSnackbar from '@/components/BaseSnackbar.vue'
import I18n from '@/i18n/index.js'
import { shadeColor } from '@/lib/color'
import bus, { eventNames } from '@/lib/eventBus'
import featureNames from '@/lib/featureNames'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'
import COMPANY_BASE from '@/queries/CompanyBase.gql'
import GET_CRM_COMPANY_CONFIG from '@/queries/GetCrmCompanyConfig.gql'
import IDENTITY from '@/queries/Identity.gql'

import Appcues from './components/Appcues.vue'
import ProductFruits from './components/ProductFruits.vue'
import Layout from './Layout.vue'
const { changeLocale } = I18n
const i18nInstance = I18n.getInstance()

export default {
  components: {
    Layout,
    ProductFruits,
    Appcues,
    Animation: defineAsyncComponent(() => import('./components/Animation.vue')),
    BaseSnackbar,
  },
  mixins: [brandingMixin, featureMixin],
  data() {
    return {
      role: { isAuthorizedFor: () => false },
      companyBaseData: {},
      // nested value to keep reactivity for provide/inject
      crmConfigData: { value: {} },
      chatInitialized: false,
    }
  },
  computed: {
    cssProps() {
      return {
        '--start-gradient-color':
          this.gradientColors.start || this.$vuetify.theme.global?.current?.colors.primary,
        '--stop-gradient-color':
          this.gradientColors.end ||
          shadeColor(this.$vuetify.theme.global?.current?.colors.primary, 90),
        '--start-gradient-color-static':
          this.staticGradientColors.start || this.$vuetify.theme.global?.current?.colors.primary,
        '--stop-gradient-color-static':
          this.staticGradientColors.end ||
          shadeColor(this.$vuetify.theme.global?.current?.colors.primary, 90),
      }
    },
  },

  created() {
    this.featureNames = featureNames
    document.title = `${this.readablePlatformName} App`
    bus.$on(eventNames.CRM_UPDATED, () => {
      this.$apollo.queries.config.refetch()
    })
  },

  updated() {
    if (this.$features.loaded && !this.chatInitialized) {
      const loadZohoChat = () => {
        const zohoChatFeature = this.$features.feature(featureNames.ZOHO_CHAT)
        zohoChatFeature?.isActive && this.loadZohoChat(zohoChatFeature?.config)
      }

      const askAiId = import.meta.env.VITE_APP_ASKAI_ID
      if (askAiId) {
        this.loadAskAiChat({ id: askAiId }, loadZohoChat)
      } else {
        loadZohoChat()
      }

      this.chatInitialized = true
    }
  },

  methods: {
    loadZohoChat(config) {
      try {
        const { widgetCode, scriptId } = config
        window.$zoho = window.$zoho || {}
        window.$zoho.salesiq = window.$zoho.salesiq || {
          widgetcode: widgetCode,
          values: {},
          ready: () => {},
        }
        const d = document
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.id = scriptId
        s.defer = true
        s.src = 'https://salesiq.zoho.eu/widget'
        const t = d.getElementsByTagName('script')[0]
        t.parentNode.insertBefore(s, t)

        // set user data
        window.$zoho.salesiq.ready = () => {
          window.$zoho.salesiq.visitor.name(this.$auth.user.name)
          window.$zoho.salesiq.visitor.email(this.$auth.user.email)
          window.$zoho.salesiq.visitor.contactnumber(this.$auth.user.phone_number)
        }
      } catch (error) {
        /* eslint-disable-next-line no-console */
        console.log('could not load zoho chat')
      }
    },

    loadAskAiChat({ id }, onLoad = () => {}) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.id = id
      script.defer = true
      script.src = `https://myaskai.com/api/1.1/wf/embed-v2?i=${id}`
      script.onload = onLoad()
      const t = document.getElementsByTagName('script')[0]
      t.parentNode.insertBefore(script, t)
    },
  },
  provide() {
    return {
      companyBaseData: this.companyBaseData,
      crmConfigData: this.crmConfigData,
      role: this.role,
    }
  },

  apollo: {
    company: {
      query: COMPANY_BASE,
      variables() {
        return {
          id: this.$auth.user.companyId,
        }
      },
      skip() {
        return this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId
      },
      result({ data }) {
        if (data) {
          Object.keys(data.company).forEach((key) => {
            this.companyBaseData[key] = data.company[key]
          })
        }
      },
    },
    identity: {
      query: IDENTITY,
      skip() {
        return this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId
      },
      result({ data }) {
        const appLocale = data?.identity.user?.appLocale
        // Stored user language has priority over browser's language or localstorage appLocale
        if (appLocale && i18nInstance.global.locale.value !== appLocale) {
          changeLocale(appLocale)
        }
        this.role.userId = data?.identity.user.id
        this.role.slug = data?.identity.user.role?.slug
        this.role.name = data?.identity.user.role?.name
        this.role.permissions = data?.identity.user.role?.permissions
        this.role.isAuthorizedFor = (permission) =>
          !this.$features.feature(this.featureNames.AUTHORIZATION)?.isActive ||
          data?.identity.user.role?.permissions.includes(permission)
      },
    },
    config: {
      query: GET_CRM_COMPANY_CONFIG,
      variables() {
        return {
          companyId: this.$auth.user.companyId,
        }
      },
      skip() {
        if (this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId) {
          return true
        }
        return !this.isFeatureActive(this.featureNames.CRM)
      },
      result({ data }) {
        if (data) {
          this.crmConfigData.value = data.config
        }
      },
    },
  },
}
</script>

<style>
@import url('./assets/css/vuetify.css');
.full-size-container {
  padding: 12px;
}

@media (min-width: 1904px) {
  .layout-container {
    max-width: 1185px;
  }
}

.btn-multiline {
  width: 250px;
}

.btn-multiline > span {
  width: 100%;
}

.primary-gradient-background {
  background: var(--start-gradient-color);
  background: linear-gradient(
    40deg,
    var(--start-gradient-color) 0%,
    var(--stop-gradient-color) 100%
  );
}

.static-gradient-background {
  background: var(--start-gradient-color-static);
  background: linear-gradient(
    40deg,
    var(--start-gradient-color-static) 0%,
    var(--stop-gradient-color-static) 100%
  );
}

.background-clip-icon {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.v-card,
.v-expansion-panel,
.v-dialog,
.v-alert {
  border-radius: 15px !important;
}

.v-text-field {
  border-radius: 10px !important;
}

.expansion-panel-border {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.w-full {
  width: 100%;
}

.gap-05 {
  gap: 0.5rem;
}
.gap-1 {
  gap: 1rem;
}
.gap-2 {
  gap: 2rem;
}
.gap-3 {
  gap: 3rem;
}
@media (min-width: 1264px) {
  .gap-lg-05 {
    gap: 0.5rem;
  }
  .gap-lg-1 {
    gap: 1rem;
  }
  .gap-lg-2 {
    gap: 2rem;
  }
  .gap-lg-3 {
    gap: 3rem;
  }
}

.newline {
  white-space: pre-line;
}

@media (max-width: 600px) {
  .w-xs-full {
    width: 100%;
  }
}

.h-full {
  height: 100%;
}

.v-dialog--fullscreen {
  border-radius: 0 !important;
  .v-card {
    border-radius: 0 !important;
  }
}
.v-btn.bg-primary {
  color: #fff !important;
}

a:not(.nav-item):not(.v-alert__content a):not(.v-btn) {
  color: rgb(var(--v-theme-primary)) !important;
}
.v-alert__content a {
  font-weight: bold;
}

.v-toolbar .v-toolbar-title {
  flex: unset;
  color: #fff !important;
}

.v-toolbar .mdi-close {
  color: #fff !important;
}

.v-breadcrumbs .v-breadcrumbs-item {
  color: rgb(var(--v-theme-primary)) !important;
}

.v-breadcrumbs .v-breadcrumbs-item {
  color: rgba(var(--v-theme-on-surface)) !important;
  opacity: 1;
}

.v-input__details .v-messages {
  margin-bottom: 4px;
  margin-top: -8px;
}

.v-messages .v-messages__message {
  text-align: left;
}

.v-field__outline {
  --v-field-border-opacity: 0.6;
}

.v-card--variant-elevated,
.v-card.elevation-1 {
  box-shadow: none !important;
}

p {
  margin-bottom: 16px;
}
.v-overlay__scrim {
  border-radius: 0;
}

.v-date-picker-month__day-btn {
  width: 36px !important;
  height: 36px !important;
}

.v-snackbar__content,
.v-dialog--fullscreen {
  z-index: 2409 !important;
}

.pfruits-widget-newsfeed {
  min-width: unset !important;
}

.back-btn {
  color: #000 !important;
}
</style>
