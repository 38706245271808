import * as Sentry from '@sentry/browser'

import { getProductPackageQuery } from '@/modules/productPackages/lib/productQuery'

export const hasRequiredPaymentData = (_, __, next) => {
  const productPackage = getProductPackageQuery()
  if (!productPackage || !productPackage.productType) {
    Sentry.captureMessage('Missing product package in payment, redirect to product-packages', {
      extra: { productPackage },
    })
    return next({ name: 'product-packages', replace: true })
  }

  return next()
}
