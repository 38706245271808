<template>
  <div :class="{ funnel: !loading }">
    <v-skeleton-loader
      v-if="loading"
      :style="{ width: $vuetify.display.mdAndUp ? '87%' : '100%' }"
      class="mx-auto"
      type="article, actions"
    />
    <v-card
      v-else
      class="pa-3 pb-0 card"
    >
      <v-card-title class="mb-2 pa-0 title">
        <span v-if="isNewUser">{{ $t('dashboard.email-funnels.new-user-title') }}</span>
        <span v-else>{{ $t('dashboard.email-funnels.title') }}</span>
      </v-card-title>
      <BlurredBackdropUpselling v-if="needsUpgrade" />
      <template v-else>
        <v-card-text
          v-if="isNewUser"
          class="pa-0 mb-1"
        >
          <div class="d-md-flex justify-md-space-between mb-4">
            <span class="label d-flex d-md-block my-2 my-md-0 mr-1">
              {{ $t('dashboard.email-funnels.new-user-labels.0') }}
            </span>
            <v-btn
              @click="configureEmails()"
              color="white"
            >
              <span class="button text-primary">{{
                $t('buttons.dashboard.email-funnels.new-user-labels.0')
              }}</span>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-text
          v-else
          class="pa-0 mb-1"
        >
          <div class="d-flex justify-space-between">
            <span class="d-flex ml-md-2 polygon">
              <span class="d-flex align-center">
                <v-avatar
                  color="rgba(255, 255, 255, 0.2)"
                  size="28"
                >
                  <v-icon
                    icon="mdi-email-arrow-right-outline"
                    color="white"
                  />
                </v-avatar>
              </span>
              <span>
                <span class="label ml-md-2 ml-1">{{
                  $t('dashboard.email-funnels.existing-user-labels.0')
                }}</span
                ><br />
                <span class="number ml-md-2 ml-1">{{ sent?.toLocaleString() || 0 }}</span>
              </span>
            </span>
            <span class="d-flex polygon">
              <span class="d-flex align-center">
                <v-avatar
                  color="rgba(255, 255, 255, 0.2)"
                  size="28"
                >
                  <v-icon
                    icon="mdi-email-open-multiple-outline"
                    color="white"
                  />
                </v-avatar>
              </span>
              <span>
                <span class="label ml-md-2 ml-1">{{
                  $t('dashboard.email-funnels.existing-user-labels.1')
                }}</span
                ><br />
                <span class="number ml-md-2 ml-1">{{ uniqueOpens?.toLocaleString() || 0 }}</span>
              </span>
            </span>
            <span class="d-flex polygon">
              <span class="d-flex align-center">
                <v-avatar
                  color="rgba(255, 255, 255, 0.2)"
                  size="28"
                >
                  <v-icon
                    icon="mdi-cursor-default-click"
                    color="white"
                  />
                </v-avatar>
              </span>
              <span>
                <span class="label ml-md-2 ml-1">{{
                  $t('dashboard.email-funnels.existing-user-labels.2')
                }}</span
                ><br />
                <span class="number ml-md-2 ml-1">{{
                  uniqueLinksClicked?.toLocaleString() || 0
                }}</span>
              </span>
            </span>
          </div>
          <v-divider class="my-3" />
          <div class="save-time-money mb-4">
            <span class="label mr-1 mr-md-4 label-mobile">{{
              $t('dashboard.email-funnels.existing-user-labels.3')
            }}</span>
            <span class="save-tme-money-numbers">
              <v-icon class="mr-1 white"> mdi-clock-time-four-outline </v-icon>
              <span class="number save-tme-money-number">
                {{ formatMinutesToHours(timeSaved || 0) }}</span
              >
              <span class="px-1 px-md-3 vertical-divider">|</span>
              <v-icon class="mr-1 white"> mdi-cash-multiple </v-icon>
              <span
                class="number save-tme-money-number"
                v-if="moneySaved"
                >{{ formatPrice(moneySaved) }}</span
              >
              <span
                class="number save-tme-money-number"
                v-else
                >{{ formatPrice(0) }}</span
              >
            </span>
          </div>
        </v-card-text>
      </template>
    </v-card>
  </div>
</template>
<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import { computed, defineEmits, getCurrentInstance, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import BlurredBackdropUpselling from '@/components/BlurredBackdropUpselling.vue'
import featureNames from '@/lib/featureNames'
import formatMinutesToHours from '@/lib/time'
import currencyMixin from '@/mixins/currency'
import featureMixin from '@/mixins/feature'
import EMAIL_FUNNELS_STATISTICS from '@/modules/statistics/GetCompanyEmailFunnelsStatistics.gql'

const router = useRouter()
const { appContext } = getCurrentInstance()!
const instance = getCurrentInstance()
const formatPrice = currencyMixin.methods.formatPrice.bind(instance.proxy)
const getFeature = featureMixin.methods.getFeature.bind(instance.proxy)
const $tracking = appContext.config.globalProperties.$tracking
const $features = appContext.config.globalProperties.$features

const hoppermationFeature = computed(() => getFeature(featureNames.HOPPERMATION))
const needsUpgrade = ref(!hoppermationFeature.value.isActive)

defineProps({
  isNewUser: Boolean,
})

const { result: emailFunnels, loading } = useQuery(EMAIL_FUNNELS_STATISTICS)
const sent = computed(() => emailFunnels.value?.companyEmailFunnelsStatistics?.sent)
const uniqueOpens = computed(() => emailFunnels.value?.companyEmailFunnelsStatistics?.uniqueOpens)
const uniqueLinksClicked = computed(
  () => emailFunnels.value?.companyEmailFunnelsStatistics?.uniqueLinksClicked
)

const MINUTES_PER_MAIL = 25
const MONEY_PER_MINUTE = 2
const factors = computed(() => {
  const config = $features?.feature(featureNames.ACQUISITION_BOOSTER)?.config?.dashboard || {}
  return {
    minutesPerMail: config.minutesPerMail || MINUTES_PER_MAIL,
    moneyPerMinute: config.moneyPerMinute || MONEY_PER_MINUTE,
  }
})

const timeSaved = computed(() => (sent.value ? sent.value * factors.value.minutesPerMail : null))

const moneySaved = computed(() =>
  sent.value ? sent.value * factors.value.minutesPerMail * factors.value.moneyPerMinute : null
)

const emit = defineEmits(['update-stats', 'update-is-new-user'])

watch([timeSaved, moneySaved], ([newTimeSaved, newMoneySaved]) => {
  if (newTimeSaved && newMoneySaved) {
    emit('update-stats', { timeSaved: newTimeSaved, moneySaved: newMoneySaved })
  }
})

watch(sent, (newSent) => {
  if (newSent) {
    emit('update-is-new-user', { sent: newSent })
  }
})

const configureEmails = async () => {
  $tracking.event('Dashboard', 'Clicked', 'Settings Email')
  router.push({ name: 'Settings', params: { section: 'email' } })
}
</script>

<style scoped>
:deep(.blur-layer) {
  background: transparent;
}
.polygon {
  width: 100%;
  background-color: #0ea29c;
  padding: 9px 6px;
  clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
}

.funnel::after {
  content: '';
  position: absolute;
  top: 99%;
  left: 43px;
  right: 43px;
  border-top: solid 20px #24a9a5;
  border-left: solid 50px transparent;
  border-right: solid 50px transparent;
}

.card {
  width: 87%;
  margin: 0 auto;
  background: #26b1ad;
}

@media (max-width: 960px) {
  .v-card {
    margin: 0;
    width: 100%;
  }
  .polygon {
    padding: 9px 4px;
  }
  .save-tme-money-number {
    min-width: max-content;
  }
}

@media (max-width: 600px) {
  :deep(.centered-alert) {
    width: 100% !important;
  }
}
</style>
