<template>
  <div :class="{ funnel: !invitationsResultsLoading && !contentsLoading }">
    <v-skeleton-loader
      v-if="invitationsResultsLoading || contentsLoading"
      :style="{ width: $vuetify.display.mdAndUp ? '97%' : '100%' }"
      class="mx-auto"
      type="article, actions"
    />
    <v-card
      v-else
      class="pa-3 pb-0 card"
    >
      <v-card-title class="mb-2 pa-0 title">{{
        $t('dashboard.contact-acquisition.title')
      }}</v-card-title>
      <BlurredBackdropUpselling v-if="needsUpgrade" />
      <template v-else>
        <v-card-text
          v-if="isNewUser"
          class="pa-0"
        >
          <div class="d-flex justify-space-between gap-1 mb-4">
            <Permission permission="lead:invite-bulk">
              <Feature :feature-slug="featureNames.ACQUISITION_BOOSTER">
                <div class="d-flex flex-column align-start">
                  <span class="label mb-3">
                    {{ $t('dashboard.contact-acquisition.new-user-labels.0') }}
                  </span>
                  <v-btn
                    @click="startReactivation()"
                    color="white"
                    class="mt-auto"
                  >
                    <span
                      class="button text-primary"
                      v-if="$vuetify.display.smAndDown"
                    >
                      {{ $t('buttons.dashboard.contact-acquisition.new-user-labels-mobile.0') }}
                    </span>
                    <span
                      class="button text-primary"
                      v-else
                    >
                      {{ $t('buttons.dashboard.contact-acquisition.new-user-labels.0') }}
                    </span>
                  </v-btn>
                </div>
              </Feature>
            </Permission>
            <Permission permission="ai:read">
              <Feature :feature-slug="featureNames.CONTENT_CREATOR">
                <div class="d-flex flex-column align-start ml-md-2">
                  <span class="label mb-3">
                    {{ $t('dashboard.contact-acquisition.new-user-labels.1') }}
                  </span>
                  <v-btn
                    @click="createAndPost()"
                    color="white"
                    class="mt-auto"
                  >
                    <span
                      class="button text-primary"
                      v-if="$vuetify.display.smAndDown"
                    >
                      {{ $t('buttons.dashboard.contact-acquisition.new-user-labels-mobile.1') }}
                    </span>
                    <span
                      class="button text-primary"
                      v-else
                    >
                      {{ $t('buttons.dashboard.contact-acquisition.new-user-labels.1') }}
                    </span>
                  </v-btn>
                </div>
              </Feature>
            </Permission>
          </div>
        </v-card-text>
        <v-card-text
          v-else
          class="pa-0 mb-1"
        >
          <div class="d-flex justify-space-between gap-1">
            <Permission permission="lead:invite-bulk">
              <Feature :feature-slug="featureNames.ACQUISITION_BOOSTER">
                <div style="display: flex; flex-direction: column">
                  <span class="label mb-1">{{
                    $t('dashboard.contact-acquisition.existing-user-labels.0')
                  }}</span>
                  <span class="label mb-3 mb-md-0">{{
                    $t('dashboard.contact-acquisition.existing-user-labels.1')
                  }}</span>
                  <span class="d-flex my-md-3 values-wrapper">
                    <span class="d-flex align-center">
                      <v-avatar
                        color="rgba(255, 255, 255, 0.2)"
                        size="28"
                      >
                        <v-icon
                          icon="mdi-account-plus"
                          color="white"
                        />
                      </v-avatar>
                    </span>
                    <span class="grid">
                      <span class="number ml-md-2 ml-1">{{
                        totalInvitationsCount?.toLocaleString() || 0
                      }}</span>
                    </span>
                  </span>
                  <v-btn
                    :to="{
                      name: 'leads-view',
                      params: { view: 'list' },
                      query: { showBooster: true },
                    }"
                    @click="$tracking.event('Dashboard', 'Clicked', 'Leads')"
                    class="button-invite-more mt-3 mt-md-0"
                    color="white"
                  >
                    <span class="button text-primary">
                      {{ $t('buttons.dashboard.contact-acquisition.existing-user-labels.0') }}
                    </span>
                  </v-btn>
                </div>
              </Feature>
            </Permission>
            <Permission permission="ai:read">
              <Feature :feature-slug="featureNames.CONTENT_CREATOR">
                <div style="display: flex; flex-direction: column">
                  <span class="label mb-1"
                    >{{ $t('dashboard.contact-acquisition.existing-user-labels.2') }}
                  </span>
                  <span class="label mb-3 mb-md-0">{{
                    $t('dashboard.contact-acquisition.existing-user-labels.3')
                  }}</span>
                  <span class="d-flex my-md-3 values-wrapper">
                    <span class="d-flex align-center">
                      <v-avatar
                        color="rgba(255, 255, 255, 0.2)"
                        size="28"
                      >
                        <v-icon
                          icon="mdi-brain"
                          color="white"
                        />
                      </v-avatar>
                    </span>
                    <span class="grid">
                      <span class="number ml-md-2 ml-1"
                        >{{ savedContentsTotal?.toLocaleString() || 0 }} /
                        {{ savedContentsNumberOfPosts?.toLocaleString() || 0 }}</span
                      >
                    </span>
                  </span>
                  <v-btn
                    @click="doMore()"
                    class="button-do-more mt-3 mt-md-0"
                    color="white"
                  >
                    <span class="button text-primary">{{
                      $t('buttons.dashboard.contact-acquisition.existing-user-labels.1')
                    }}</span>
                  </v-btn>
                </div>
              </Feature>
            </Permission>
          </div>
          <v-divider class="my-3" />
          <div class="save-time-money mb-4">
            <span class="label mr-1 mr-md-4">{{
              $t('dashboard.contact-acquisition.existing-user-labels.4')
            }}</span>
            <span class="save-tme-money-numbers">
              <v-icon
                class="mr-1"
                color="white"
              >
                mdi-clock-time-four-outline
              </v-icon>
              <span class="number save-tme-money-number">{{
                formatMinutesToHours(timeSaved)
              }}</span>
              <span class="px-1 px-md-3 vertical-divider">|</span>
              <v-icon
                class="mr-1"
                color="white"
              >
                mdi-cash-multiple
              </v-icon>
              <span class="number save-tme-money-number">{{ formatPrice(moneySaved) }}</span>
            </span>
          </div>
        </v-card-text>
      </template>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import { computed, defineEmits, getCurrentInstance, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

import BlurredBackdropUpselling from '@/components/BlurredBackdropUpselling.vue'
import Permission from '@/components/Permission'
import featureNames from '@/lib/featureNames'
import formatMinutesToHours from '@/lib/time'
import currencyMixin from '@/mixins/currency'
import featureMixin from '@/mixins/feature'
import INVITATION_STATISTICS_FOR_COMPANY from '@/modules/acquisitionBooster/InvitationStatisticsForCompany.gql'
import GET_SAVED_CONTENTS from '@/modules/contentCreator/ContentCreatorDasboard/getSavedContents.gql'
import { Feature } from '@/plugins/feature-config'

const router = useRouter()
const { appContext } = getCurrentInstance()!
const $tracking = appContext.config.globalProperties.$tracking
const $features = appContext.config.globalProperties.$features
const instance = getCurrentInstance()
const formatPrice = currencyMixin.methods.formatPrice.bind(instance.proxy)
const getFeature = featureMixin.methods.getFeature.bind(instance.proxy)

defineProps({
  isNewUser: Boolean,
})

const MINUTES_PER_POST = 45
const MONEY_PER_MINUTE = 2.5
const MINUTES_PER_MAIL = 25

const factors = computed(() => {
  const dashboardConfig = $features?.feature(featureNames.CONTENT_CREATOR)?.config?.dashboard
  return {
    minutesPerPost: dashboardConfig?.minutesPerPost || MINUTES_PER_POST,
    moneyPerMinute: dashboardConfig?.moneyPerMinute || MONEY_PER_MINUTE,
  }
})

const { result: contents, loading: contentsLoading } = useQuery(GET_SAVED_CONTENTS, {
  input: {
    perPage: 1,
    page: 1,
  },
})

const savedContentsTotal = computed(() => contents.value?.savedContents.total)
const savedContentsNumberOfPosts = computed(() => contents.value?.savedContents.numberOfPosts)

const savedContentsTimeSaved = computed(() =>
  contents.value?.savedContents.total
    ? contents.value?.savedContents.total * factors.value.minutesPerPost
    : null
)

const savedContentsMoneySaved = computed(() =>
  contents.value?.savedContents.total
    ? contents.value?.savedContents.total *
      factors.value.minutesPerPost *
      factors.value.moneyPerMinute
    : null
)

const { result: invitationsResults, loading: invitationsResultsLoading } = useQuery(
  INVITATION_STATISTICS_FOR_COMPANY
)

const totalInvitationsCount = computed(
  () => invitationsResults.value?.invitationStatisticsForCompany?.total
)
const invitationFactors = computed(() => {
  const config = $features?.feature(featureNames.ACQUISITION_BOOSTER)?.config?.dashboard
  const { minutesPerMail = MINUTES_PER_MAIL, moneyPerMinute = MONEY_PER_MINUTE } = config || {}
  return {
    minutesPerMail,
    moneyPerMinute,
  }
})

const invitationsTimeSaved = computed(
  () =>
    totalInvitationsCount.value &&
    totalInvitationsCount.value * invitationFactors.value.minutesPerMail
)

const invitationsMoneySaved = computed(
  () =>
    totalInvitationsCount.value &&
    totalInvitationsCount.value *
      invitationFactors.value.minutesPerMail *
      invitationFactors.value.moneyPerMinute
)

const timeSaved = computed(() => savedContentsTimeSaved.value + invitationsTimeSaved.value)
const moneySaved = computed(() => savedContentsMoneySaved.value + invitationsMoneySaved.value)

const emit = defineEmits(['update-stats'])

watchEffect(() => {
  if (timeSaved.value && moneySaved.value) {
    emit('update-stats', { timeSaved: timeSaved.value, moneySaved: moneySaved.value })
  }
})

const aquisitionBoosterFeature = computed(() => getFeature(featureNames.ACQUISITION_BOOSTER))
const contentCreatorFeature = computed(() => getFeature(featureNames.CONTENT_CREATOR))

const needsUpgrade = ref(
  !aquisitionBoosterFeature.value.isActive && !contentCreatorFeature.value.isActive
)
const doMore = async () => {
  $tracking.event('Dashboard', 'Clicked', 'Content Creator')
  router.push({ name: 'content-creator' })
}

const createAndPost = async () => {
  $tracking.event('Dashboard', 'Clicked', 'Content Creator')
  router.push({ name: 'content-creator' })
}
const startReactivation = async () => {
  $tracking.event('Dashboard', 'Clicked', 'Leads')
  router.push({ name: 'leads' })
}
</script>

<style scoped>
.progress-bar {
  margin: auto;
}
.product-fruits-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
:deep(.blur-layer) {
  background: transparent;
}

.funnel::after {
  content: '';
  position: absolute;
  top: 99%;
  left: 15px;
  right: 15px;
  border-top: solid 20px #21827f;
  border-left: solid 50px transparent;
  border-right: solid 50px transparent;
}
.card {
  width: 97%;
  margin: 10px auto 0 auto;
  background: #1d8a86;
}

@media (max-width: 960px) {
  .values-wrapper {
    margin-top: auto;
  }
  .v-card {
    margin: 0;
    width: 100%;
  }
  .save-tme-money-number {
    min-width: max-content;
  }
}
@media (max-width: 400px) {
  .button {
    font-size: 11px;
  }
}

@media (max-width: 600px) {
  :deep(.centered-alert) {
    width: 100% !important;
  }
}
</style>
