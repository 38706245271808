/**
 * Vue I18n is an internationalization plugin for Vue.js. It easily integrates localization features.
 *
 * This file creates a vue-i18n instance with the following configuration:
 *    const i18n = createI18n({
 *      locale: 'en', // set locale
 *      messages, // set locale messages
 *    })
 *
 * Usage:
 *
 * In Vue components, you can now use the t method to translate your application.
 * Template example:
 *    <p>{{ t('foo.bar') }}</p>
 *
 *    <MyComponent :label="t('foo.bar')" />
 *
 * Script example using Composition API:
 *    import { useI18n } from 'vue-i18n'
 *    const { t } = useI18n()
 *    const label = t('foo.bar')
 *
 * You can change the locale using the locale.value property:
 *    const { locale } = useI18n()
 *    locale.value = 'fr'
 *
 * The translation messages are located in the src/i18n//locales directory, organized by language and namespace.
 *
 * For more information, check out the official Vue I18n documentation: https://vue-i18n.intlify.dev/
 */
import { createI18n } from 'vue-i18n'

import deepMergeObjects from '@/lib/deepMerge'
import features from '@/lib/features'

// Import translations using Vite's glob import
// This will import all JSON files from all language directories
const translations = import.meta.glob('./locales/**/*.json', {
  eager: true,
  import: 'default',
})

// Process translations into messages object and detect available languages
const messages = {}

// Regular expression to match the language and namespace from the path
// Format: ./locales/{lang}/{namespace}.json
const pathRegex = /\.\/locales\/([^/]+)\/([^/]+)\.json$/

// Parse translations from glob imports
Object.entries(translations).forEach(([path, translation]) => {
  const match = path.match(pathRegex)
  if (match) {
    const [, lang, namespace] = match

    // Initialize language object if not exists
    if (!messages[lang]) {
      messages[lang] = {}
    }

    // If it's the bottimmo namespace, store it separately
    if (namespace === 'bottimmo') {
      // We'll handle bottimmo overrides separately
      return
    }

    // Merge the namespace translations into the language object
    messages[lang][namespace] = translation
  }
})

// Get the list of available languages from the detected translations
const availableLocales = Object.keys(messages)

const STORAGE_KEY = 'appLocale'

/**
 * Retrieves the locale from localStorage
 * @returns {string|null} The stored locale or null if not found
 */
function getLocaleFromStorage() {
  return localStorage.getItem(STORAGE_KEY)
}

/**
 * Saves the locale to localStorage
 * @param {string} locale The locale to store
 */
function saveLocaleToStorage(locale) {
  localStorage.setItem(STORAGE_KEY, locale)
}

/**
 * Gets the locale from browser settings if it matches available locales
 * @returns {string|null} The matched locale or null if none found
 */
function getBrowserLocale() {
  for (const browserLocale of window.navigator.languages) {
    if (availableLocales.includes(browserLocale)) {
      return browserLocale
    } else {
      const language = browserLocale.split('-')[0]
      if (availableLocales.includes(language)) {
        return language
      }
    }
  }
  return null
}

/**
 * Gets the default locale from features config or falls back to 'en'
 * @returns {string} The default locale
 */
function getDefaultLocale() {
  return features?.i18n?.config?.defaultLocale || 'en'
}

/**
 * Determines the client locale based on storage, browser settings, or default
 * @returns {string} The determined locale
 */
function getClientLocale() {
  const selectedLocale = getLocaleFromStorage()
  if (selectedLocale && availableLocales.includes(selectedLocale)) {
    return selectedLocale
  }

  const browserLocale = getBrowserLocale()
  if (browserLocale) {
    return browserLocale
  }

  return getDefaultLocale()
}

// Merge all namespaces for each language into a single object
// This maintains compatibility with the previous setup that used a single file per language
const mergedMessages = {}
Object.entries(messages).forEach(([lang, namespaceObjects]) => {
  // Initialize the language object if it doesn't exist
  if (!mergedMessages[lang]) {
    mergedMessages[lang] = {}
  }

  // Merge all namespace objects into a single object for this language
  Object.entries(namespaceObjects).forEach(([, translations]) => {
    mergedMessages[lang] = deepMergeObjects(mergedMessages[lang], translations)
  })
})

// Initialize i18n instance immediately
const i18nInstance = createI18n({
  locale: getClientLocale(),
  fallbackLocale: getDefaultLocale(),
  messages: mergedMessages,
  legacy: false, // Enable Composition API mode
})

/**
 * Loads and applies translation overrides asynchronously
 */
async function loadOverrides() {
  // Check if we need to apply specific overrides from features config
  if (features?.i18n?.config?.languageOverrides) {
    for (const [lang, fileNameWithSuffix] of Object.entries(
      features.i18n.config.languageOverrides
    )) {
      // Skip if language is not available
      if (!mergedMessages[lang]) continue

      try {
        // Extract the namespace from the filename (remove the language suffix)
        // e.g., 'markero_en' -> 'markero' or 'bottimmo_de' -> 'bottimmo'
        const namespace = fileNameWithSuffix.split('_')[0]

        // Try to load the override file using the namespace
        const overridePath = `./locales/${lang}/${namespace}.json`

        if (translations[overridePath]) {
          const override = translations[overridePath]
          const updatedMessages = deepMergeObjects(
            i18nInstance.global.getLocaleMessage(lang),
            override
          )
          i18nInstance.global.setLocaleMessage(lang, updatedMessages)
        } else {
          console.warn(`Override file not found: ${overridePath}`)
        }
      } catch (error) {
        console.error(`Failed to load override for ${lang}:`, error)
      }
    }
  }
}

// Start loading overrides but don't block initialization
loadOverrides()

const i18n = {
  availableLocales,

  /**
   * Returns the i18n instance.
   * @returns {Object} i18n instance
   */
  getInstance() {
    return i18nInstance
  },

  /**
   * Changes the locale of the i18n instance and saves it to the local storage.
   * @param {string} locale The new locale to set
   */
  changeLocale(locale) {
    i18nInstance.global.locale.value = locale
    saveLocaleToStorage(locale)
  },
}

export default i18n
