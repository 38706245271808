import { useGtm } from '@gtm-support/vue-gtm'

export function useGTM() {
  const gtm = useGtm()

  const enableGTM = (enabled) => {
    if (gtm) {
      gtm.enable(enabled)
    }
  }

  return {
    gtm,
    enableGTM,
  }
}
