/**
 * Creates a debounced function that delays invoking the provided function until after the specified wait time has elapsed.
 * The returned function includes a `cancel` method to clear any pending invocation.
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The number of milliseconds to delay.
 * @returns {Function} - The debounced function with a cancel method.
 */
export function debounce(func, wait) {
  let timeout

  const debounced = function (...args) {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }

  debounced.cancel = function () {
    clearTimeout(timeout)
  }

  return debounced
}
