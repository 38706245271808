query getTopics {
  topics: getActiveTopics {
    id
    name
    description
    isSystemGenerated
    createdAt
    landingpage {
      id
      type
      slug
      name
      status
      url
    }
  }
}
