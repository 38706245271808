// auth/index.js
import { reactive } from 'vue'

import { isOnofficeProvider } from './onoffice'

let instance

export const getInstance = () => instance

export const useAuth = ({ auth0, onofficeAuth }) => {
  if (instance) return instance

  instance = reactive({
    provider: null,
    loading: true,
    isAuthenticated: false,
    user: {},
    error: null,
    auth0Client: null,
    webAuth: null,

    async init() {
      this.provider = isOnofficeProvider() ? onofficeAuth : auth0
      await this.provider.onCreated()
      this.syncState()
    },

    syncState() {
      this.loading = this.provider.loading
      this.isAuthenticated = this.provider.isAuthenticated
      this.user = this.provider.user
      this.error = this.provider.error
      this.auth0Client = this.provider.auth0Client
      this.webAuth = this.provider.webAuth
    },

    async login(...params) {
      const result = await this.provider.login(...params)
      this.syncState()
      return result
    },

    loginWithRedirect(...params) {
      return this.provider.loginWithRedirect(...params)
    },

    async logout(...params) {
      await this.provider.logout(...params)
      this.syncState()
    },

    changePassword(...params) {
      return this.provider.changePassword(...params)
    },

    getIdTokenClaims(...params) {
      return this.provider.getIdTokenClaims(...params)
    },

    async getTokenSilently(...params) {
      const token = await this.provider.getTokenSilently(...params)
      this.syncState()
      return token
    },

    rememberLocationBeforeLogin() {
      sessionStorage.setItem('locationBeforeLogin', window.location.pathname)
    },
  })

  instance.init()
  return instance
}

export const AuthPlugin = {
  install(app, options) {
    const auth = useAuth(options)
    app.config.globalProperties.$auth = auth
    app.provide('auth', auth)
  },
}
