import { appBranding } from '@/lib/features'

export default {
  computed: {
    platformAssetUrl() {
      return appBranding?.isActive && appBranding?.config?.assetUrl
    },
    platform() {
      return appBranding?.isActive && appBranding?.config?.platform
    },
    isPlatformMarkero() {
      return appBranding?.isActive && appBranding?.config?.platform === 'markero'
    },
    legalCompanyName() {
      return appBranding?.isActive && appBranding?.config?.legalCompanyName
    },
    readablePlatformName() {
      return appBranding?.isActive && appBranding?.config?.readablePlatformName
    },
    dataPrivacyUrl() {
      return appBranding?.isActive && appBranding?.config?.dataPrivacyUrl
    },
    publicDataPrivacyUrl() {
      return appBranding?.isActive && appBranding?.config?.publicDataPrivacyUrl
    },
    publicTOCandLicenceUrl() {
      return appBranding?.isActive && appBranding?.config?.publicTOCandLicenceUrl
    },
    imprintUrl() {
      return appBranding?.isActive && appBranding?.config?.imprintUrl
    },
    primaryColor() {
      return appBranding?.isActive && appBranding?.config?.primary
    },
    gradientColors() {
      return {
        start: (appBranding?.isActive && appBranding?.config?.primary) || '#07BEB8',
        end: (appBranding?.isActive && appBranding?.config?.loginBackgroundColor) || '#0896c9',
      }
    },
    staticGradientColors() {
      return {
        start: (appBranding?.isActive && `${appBranding?.config?.primary}99`) || '#07BEB899',
        end:
          (appBranding?.isActive && `${appBranding?.config?.loginBackgroundColor}99`) ||
          '#0896c999',
      }
    },
  },
}
