import featureNames from '@/lib/featureNames'

const Permission = {
  props: {
    permission: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  name: 'Permission',
  inject: ['role'],
  render() {
    const isFeatureActive = !!this.$features.feature(featureNames.AUTHORIZATION)?.isActive
    const hasPermission = this.role?.permissions?.includes(this.permission)

    if (!isFeatureActive || (isFeatureActive && hasPermission) || this.show) {
      if (this.$slots.default) {
        return this.$slots.default({
          hasPermission: !isFeatureActive || (isFeatureActive && hasPermission),
        })
      }
    } else if (isFeatureActive && !hasPermission) {
      if (this.$slots.noPermission) {
        return this.$slots.noPermission()
      }
    }
  },
}

export default Permission
