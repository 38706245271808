import featureNames from '@/lib/featureNames.js'

export const BASIC_NAVIGATION = [
  {
    label: 'navigation.labels.dashboard',
    trackingLabel: 'Dashboard',
    to: '/dashboard',
    icon: 'mdi-view-dashboard-outline',
  },
  {
    label: 'navigation.labels.topics',
    trackingLabel: 'Topics',
    to: '/topics',
    icon: 'mdi-database',
    feature: featureNames.TOPIC,
    checkConfigIsActive: (config) => config?.isUserManageable ?? false,
  },
  {
    label: 'navigation.labels.leads',
    trackingLabel: 'Leads',
    to: '/leads',
    icon: 'mdi-account-multiple-outline',
    feature: featureNames.LEAD,
    permission: 'lead:read',
  },
  {
    label: 'navigation.labels.landingpages',
    trackingLabel: 'Landingpages',
    to: '/landingpages',
    icon: 'mdi-button-pointer',
    feature: featureNames.LANDINGPAGE,
    permission: 'material:read',
  },
  {
    label: 'navigation.labels.content-creator',
    trackingLabel: 'Content Creator',
    to: '/content-creator',
    icon: 'mdi-brain',
    feature: featureNames.CONTENT_CREATOR,
    permission: 'ai:read',
  },
  {
    label: 'navigation.labels.valuation',
    trackingLabel: 'Valuation',
    to: '/valuation',
    icon: 'mdi-file-chart-check-outline',
    feature: featureNames.VALUATION,
    checkConfigIsActive: (config) => config.isInAppValuationEnabled,
    permission: 'valuation:read',
  },
  {
    label: 'navigation.labels.partner',
    trackingLabel: 'Partner',
    to: '/partner',
    icon: 'mdi-handshake-outline',
    feature: featureNames.PARTNER,
    permission: 'partner:read',
  },
  {
    label: 'navigation.labels.performance',
    trackingLabel: 'Performance Report',
    to: '/performance',
    icon: 'mdi-gauge',
    feature: featureNames.PERFORMANCE_MARKETING,
    permission: 'performance:read',
  },
  {
    label: 'navigation.labels.statistic-dashboard',
    trackingLabel: 'Statistic Dashboard',
    to: '/statistic-dashboard',
    icon: 'mdi-poll',
    feature: featureNames.STATISTIC_DASHBOARD,
    permission: 'lead:read',
  },
  {
    label: 'navigation.labels.settings',
    trackingLabel: 'Settings',
    to: '/settings',
    icon: 'mdi-cog-outline',
    permission: 'settings:read',
  },
]

export const ONBOARDING_NAVIGATION = [
  {
    label: 'navigation.labels.onboarding',
    trackingLabel: 'Onboarding',
    to: '/onboarding',
    icon: 'mdi-view-dashboard-outline',
  },
]
