<template>
  <div class="d-flex align-center px-2">
    <v-icon class="static-gradient-background background-clip-icon rounded-circle pa-1">
      {{ icon }}
    </v-icon>
    <span class="text-body-1 item text-no-wrap text--darken-3 pl-1">
      {{ service }} <b>{{ count }}</b></span
    >
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    service: {
      type: String,
      default: '',
    },
    count: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style scoped>
.item {
  min-width: 100px;
}
</style>
