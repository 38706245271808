<template>
  <v-btn
    rounded="md"
    class="upgrade-button"
    color="primary"
    :variant="variant"
    :to="{ name: 'Settings', params: { section: 'contract' }, query: { showUpgrade: '1' } }"
    @click="trackClick"
    :width="showLabel ? '' : '50px'"
  >
    <v-icon class="upgrade-button--text">mdi-one-up</v-icon>
    <v-expand-x-transition>
      <span
        v-if="showLabel"
        class="upgrade-button-label ml-2 upgrade-button--text"
        >{{ label }}</span
      >
    </v-expand-x-transition>
  </v-btn>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    showLabel?: boolean
    label?: string
    variant?: 'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain' | undefined
  }>(),
  {
    showLabel: false,
    label: '',
    variant: 'elevated',
  }
)

const emit = defineEmits<{
  (e: 'track-click'): void
}>()

const trackClick = () => {
  emit('track-click')
}
</script>

<style scoped>
.upgrade-button {
  text-transform: unset;
  font-weight: 700;
  padding: 0 8px !important;
  max-width: 100%;
  min-width: unset;
}
</style>
