import featureNames from '@/lib/featureNames'

const getAllNavItems = (languageCode, features) => {
  const navItems = []
  const feature = features.feature(featureNames.PRODUCTFRUITS)
  if (feature?.isActive) {
    navItems.push({
      label: 'navigation.labels.news',
      trackingLabel: 'News',
      icon: 'mdi-bell-outline',
      onboarding: false,
    })
  }

  const supportNavItem = createSupportNavItem(languageCode, features)
  if (supportNavItem) {
    navItems.push(supportNavItem)
  }

  navItems.push({
    label: 'navigation.labels.legal',
    trackingLabel: 'Legal',
    to: '/legal',
    onboarding: true,
    icon: 'mdi-file-document-outline',
    permission: 'legal:read',
  })

  return navItems
}

const createSupportNavItem = (languageCode, features) => {
  const support = {
    label: 'navigation.labels.support',
    trackingLabel: 'HelpCenter',
    icon: 'mdi-lifebuoy',
    onboarding: false,
    permission: 'support:read',
  }

  const feature = features.feature(featureNames.PRODUCTFRUITS)
  if (feature?.isActive && feature.config.helpCenter) {
    const availableLanguages = feature.config.availableLanguages || []
    const fallbackLanguage = feature.config.fallbackLanguage || 'de'
    const lang = availableLanguages.includes(languageCode) ? languageCode : fallbackLanguage
    support.href = feature.config.helpCenter.replace('{lang}', lang)
    support.target = '_blank'
  } else {
    support.to = '/support'
  }

  return support
}

export const navItemsBottimmo = (languageCode, inOnboarding, isInactive, features, role) => {
  return inOnboarding || isInactive
    ? getActiveNavItems(
        getAllNavItems(languageCode, features).filter(({ onboarding }) => onboarding),
        features,
        role
      )
    : getActiveNavItems(getAllNavItems(languageCode, features), features, role)
}

export const getActiveNavItems = (items, features, role) => {
  return items.filter(({ feature: featureSlug, checkConfigIsActive, permission }) => {
    const feature = featureSlug ? features.feature(featureSlug) : null
    if (
      feature &&
      (!feature.isActive || (checkConfigIsActive && !checkConfigIsActive(feature.config)))
    ) {
      return false
    }

    if (permission && !role.isAuthorizedFor(permission)) {
      return false
    }

    return true
  })
}
