query LeadsWithLocation {
  leads: leadsWithLocation {
    id
    email
    firstname
    lastname
    createdAt
    currentStatus
    conversions {
      id
      valuation {
        id
        status
        inputs {
          type
          buildingYear
          landArea
          livingArea
          numberOfRooms
          address {
            street
            houseNumber
            city
            zip
          }
          coordinates {
            lat
            lng
          }
        }
        results {
          currency
          property {
            value
            valueRange {
              min
              max
            }
          }
        }
      }
      propertyRequest {
        property {
          minPrice
          maxPrice
          location {
            coordinates {
              lat
              lng
            }
            radius
            description
          }
        }
      }
    }
  }
}
