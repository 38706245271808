export default {
  methods: {
    formatPrice(price, currency, fractionDigits = 0) {
      const { locale = 'de-DE' } = this.$auth?.user || {}
      const currencies = { 'de-DE': 'EUR', 'de-AT': 'EUR', 'de-CH': 'CHF', 'tr-TR': 'TRY' }
      const defaultCurrency = 'EUR'

      return new Intl.NumberFormat(locale, {
        currency: currency || currencies[locale] || defaultCurrency,
        style: 'currency',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }).format(price)
    },
  },
}
