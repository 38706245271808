<template>
  <div :class="{ funnel: !isNewUser && !loading }">
    <v-skeleton-loader
      v-if="loading"
      :style="{ width: $vuetify.display.mdAndUp ? '82%' : '100%' }"
      class="mx-auto"
      type="article, actions"
    />
    <v-card
      v-else
      :class="{ 'card-new-user': isNewUser }"
      class="pa-3 pb-0 card"
    >
      <v-card-title class="mb-2 pa-0 title">
        <span v-if="isNewUser">{{ $t('dashboard.gained-contacts.new-user-title') }}</span>
        <span v-else>{{ $t('dashboard.gained-contacts.title') }}</span>
      </v-card-title>
      <Permission permission="lead:read">
        <Feature :feature-slug="featureNames.COMPANY">
          <Feature :feature-slug="featureNames.LEAD_IMPORT">
            <v-card-text
              v-if="isNewUser"
              class="pa-0 mb-1"
            >
              <span class="label">
                {{ $t('dashboard.gained-contacts.new-user-labels.0') }}
              </span>
              <div class="d-flex justify-space-between gap-1 mb-4 my-2">
                <Feature :feature-slug="featureNames.CRM">
                  <v-btn
                    @click="connectCrm()"
                    color="white"
                    class="button-bonnect-crm"
                  >
                    <span class="text-primary button">
                      {{ $t('buttons.dashboard.gained-contacts.new-user-labels.0') }}
                    </span>
                  </v-btn>
                </Feature>

                <v-btn
                  @click="importContacts()"
                  color="white"
                  class="button-import-contacts"
                >
                  <span class="text-primary button">
                    {{ $t('buttons.dashboard.gained-contacts.new-user-labels.1') }}
                  </span>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-text
              v-else
              class="pa-0 mb-1"
            >
              <div class="d-flex justify-space-between mb-3">
                <span class="mr-md-2">
                  <span class="label mb-1">{{
                    $t('dashboard.gained-contacts.existing-user-labels.0')
                  }}</span>
                  <span class="d-flex mt-1">
                    <span class="d-flex align-center">
                      <v-avatar
                        color="rgba(255, 255, 255, 0.2)"
                        size="28"
                      >
                        <v-icon
                          icon="mdi-account-multiple"
                          color="white"
                        />
                      </v-avatar>
                    </span>
                    <span class="grid">
                      <span class="number ml-md-2 ml-1">
                        {{ totalLeadCount?.toLocaleString() || 0 }}</span
                      >
                    </span>
                  </span>
                </span>
                <span class="mr-md-2">
                  <span class="label">{{
                    $t('dashboard.gained-contacts.existing-user-labels.1')
                  }}</span>
                  <span class="d-flex mt-1">
                    <span class="d-flex align-center">
                      <v-avatar
                        color="rgba(255, 255, 255, 0.2)"
                        size="28"
                      >
                        <v-icon
                          icon="mdi-bank"
                          color="white"
                        />
                      </v-avatar>
                    </span>
                    <span class="grid">
                      <span class="number ml-md-2 ml-1">{{
                        totalLeadCountWithValuation?.toLocaleString() || 0
                      }}</span>
                    </span>
                  </span>
                </span>
                <span class="mr-md-2">
                  <span class="label">{{
                    $t('dashboard.gained-contacts.existing-user-labels.2')
                  }}</span>
                  <span class="d-flex mt-1">
                    <span class="d-flex align-center">
                      <v-avatar
                        color="rgba(255, 255, 255, 0.2)"
                        size="28"
                      >
                        <v-icon
                          icon="mdi-account-plus"
                          color="white"
                        />
                      </v-avatar>
                    </span>
                    <span class="grid">
                      <span class="number ml-md-2 ml-1">{{
                        totalLeadCountLastThirtyDays?.toLocaleString() || 0
                      }}</span>
                    </span>
                  </span>
                </span>
              </div>
              <div class="button-get-more-wrapper">
                <v-btn
                  class="mb-4 mt-2 button-get-more"
                  @click="getMore()"
                  :class="{ 'float-right': $vuetify.display.mdAndUp }"
                  color="white"
                >
                  <span class="button text-primary">
                    {{ $t('buttons.dashboard.gained-contacts.existing-user-labels.0') }}
                  </span>
                </v-btn>
              </div>
            </v-card-text>
          </Feature>
        </Feature>
      </Permission>
    </v-card>
  </div>
</template>
<script setup lang="ts">
import { computed, getCurrentInstance, PropType } from 'vue'
import { useRouter } from 'vue-router'

import Permission from '@/components/Permission'
import featureNames from '@/lib/featureNames'
import { PARTNER_TAGS } from '@/modules/partner/helper/partner'

const router = useRouter()
const { appContext } = getCurrentInstance()!
const $tracking = appContext.config.globalProperties.$tracking
import { Feature } from '@/plugins/feature-config'

const props = defineProps({
  statistics: Object as PropType<Record<string, any>>,
  isNewUser: Boolean,
  loading: Boolean,
})

const loading = computed(() => props.loading)
const totalLeadCount = computed(() => props.statistics?.totalLeadCount?.total)
const totalLeadCountLastThirtyDays = computed(() => props.statistics?.totalLeadCount.lastThirtyDays)
const totalLeadCountWithValuation = computed(() => props.statistics?.totalLeadCount.withValuation)

const getMore = async () => {
  $tracking.event('Dashboard', 'Clicked', 'Partner')
  router.push({
    name: 'partner',
    query: { filters: [PARTNER_TAGS.SEO, PARTNER_TAGS.SOCIAL_MEDIA] },
  })
}
const connectCrm = async () => {
  $tracking.event('Dashboard', 'Clicked', 'Settings CRM')
  router.push({ name: 'Settings', params: { section: 'crm' } })
}
const importContacts = async () => {
  $tracking.event('Dashboard', 'Clicked', 'Leads Import')
  router.push({
    name: 'leads-view',
    params: { view: 'list' },
    query: { showImportContacts: true },
  })
}
</script>

<style scoped>
.progress-bar {
  margin: auto;
}
:deep(.blur-layer) {
  background: transparent;
}

.button-get-more-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.funnel::after {
  content: '';
  position: absolute;
  top: 99%;
  left: 58px;
  right: 58px;
  border-top: solid 20px #22b6b1;
  border-left: solid 50px transparent;
  border-right: solid 50px transparent;
}

.card {
  width: 82%;
  margin: 0 auto;
  background: #2ac5c0;
}

.card-new-user {
  margin-bottom: 16px;
}

@media (max-width: 960px) {
  .v-card {
    margin: 0;
    width: 100%;
  }
  .button-get-more {
    width: 100%;
  }
  .button-bonnect-crm,
  .button-import-contacts {
    width: 47%;
  }
}

@media (max-width: 600px) {
  :deep(.centered-alert) {
    width: 100% !important;
  }
}
</style>
