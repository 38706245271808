<template>
  <div :style="cssProps">
    <div class="text-center pb-6">
      <h2 class="text-h6">
        {{ $t('reset-password.confirmation.title') }}
      </h2>
    </div>

    <div class="text-center pb-6">
      <v-icon
        color="success"
        size="80"
      >
        mdi-check-circle
      </v-icon>
    </div>

    <div class="text-center pb-6">
      <h2 class="subtitle">
        {{ $t('reset-password.confirmation.subtitle') }}
        <strong class="text-no-wrap">{{ email }}</strong
        >.
      </h2>
    </div>

    <div class="text-center pb-6">
      <div class="link">
        <v-btn
          class="resend-btn"
          @click="$emit('back')"
        >
          {{ $t('buttons.reset-password.back') }}
        </v-btn>
      </div>
    </div>

    <div class="text-center pb-6">
      <h2 class="link">
        {{ $t('reset-password.confirmation.link-info') }}
        <a
          class="resend-link"
          @click="$emit('resend')"
        >
          {{ $t('reset-password.confirmation.link') }}.
        </a>
      </h2>
    </div>
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'

export default {
  mixins: [brandingMixin],
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  emits: ['back', 'resend'],
  computed: {
    cssProps() {
      return {
        '--markero-blue': this.$vuetify.theme.global?.current?.colors['markero-blue'],
      }
    },
  },
  methods: {
    back() {
      this.$router.push({ name: 'password-reset-request' })
    },
  },
}
</script>
<style scoped>
.title,
.subtitle,
.link,
.resend-btn,
.resend-link {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
}
.title,
.subtitle {
  color: #252525;
  text-align: center;
}

.title {
  font-size: 28px !important;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.link {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.resend-btn:hover {
  animation-timing-function: ease-out;
  animation-duration: 300ms;
}

.resend-btn {
  background-color: var(--markero-blue) !important;
  color: white;
  box-shadow: 5px 5px 15px 0 #2f16e840;
  width: 100% !important;
  height: 52px !important;
  padding: 14px 34px !important;
  gap: 10px;
  border-radius: 10px;
}

.resend-link {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-left: 5px;
  color: var(--markero-blue) !important;
}

.bubble {
  margin: 0 auto;
}
</style>
