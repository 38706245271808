<template>
  <!-- TODO: check unsuported prop: lazy-validation -->
  <v-form
    ref="inputData"
    v-model="valid"
    name="create-user-feedback"
    @change="error = ''"
  >
    <v-container>
      <v-expand-transition>
        <v-alert
          v-if="error"
          type="error"
          density="compact"
          tile
          text
        >
          {{ error }}
        </v-alert>
      </v-expand-transition>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="classification"
            :label="$t('labels.topic') + '*'"
            :items="classificationList"
            :rules="[rules.required]"
            density="compact"
            @update:model-value="handleChange"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="subject"
            :label="$t('labels.subject') + '*'"
            :rules="[rules.required]"
            density="compact"
            @change="handleChange"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="description"
            :label="$t('support.support-form-card.description') + '*'"
            :rules="[rules.required]"
            variant="filled"
            density="compact"
            @change="handleChange"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import * as Sentry from '@sentry/vue'

import bus, { eventNames } from '@/lib/eventBus'
import { required } from '@/lib/validation'

import CREATE_USER_FEEDBACK_MUTATION from './CreateUserFeedbackMutation.gql'

export default {
  props: {
    classificationList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valid: true,
      subject: '',
      description: '',
      error: '',
      classification: null,
    }
  },
  computed: {
    rules() {
      return {
        required,
      }
    },
  },
  mounted() {
    bus.$on(eventNames.SUBMIT_FEEDBACK_FORM, this.handleSubmit)
    bus.$on(eventNames.RESET_FEEDBACK_FORM, this.handleReset)
  },
  methods: {
    async handleSubmit() {
      this.error = ''
      const { valid } = await this.$refs.inputData.validate()
      if (valid) {
        try {
          await this.$apollo.mutate({
            mutation: CREATE_USER_FEEDBACK_MUTATION,
            variables: {
              input: {
                subject: this.subject,
                description: this.description,
                classification: this.classification,
              },
            },
          })
          this.$emit('feddbackSubmitted')
        } catch (error) {
          this.error = 'Die Nachricht konnte nicht verschickt werden'
          Sentry.addBreadcrumb({
            category: 'zoho desk ticket',
            description: 'Could not create Zoho feedback ticket.',
          })
          Sentry.captureException(error)
        }
      }
    },
    handleReset() {
      this.$refs.inputData.reset()
      this.$refs.inputData.resetValidation()
      this.subject = ''
      this.description = ''
      this.classification = null
      this.error = ''
      this.$emit('formValuesChange', { state: 'idle' })
    },
    handleChange() {
      const state =
        this.subject === '' && this.description === '' && this.classification === null
          ? 'idle'
          : 'changed'
      this.$emit('formValuesChange', { state })
    },
  },
  emits: ['feddbackSubmitted', 'formValuesChange'],
}
</script>

<style></style>
