<template>
  <div class="navbar">
    <!-- TODO: check unsupported prop: clipped-left, app -->
    <v-app-bar
      v-if="$auth.isAuthenticated && this.display.mdAndDown"
      color="white"
      height="54"
    >
      <template v-slot:prepend>
        <NavigationImage
          :alt="$t('navigation.logo.alt-texts.0')"
          :width="130"
          :src="logo"
        />
      </template>

      <div class="d-flex flex-row align-center">
        <UpgradeButton
          :label="$t('navigation.usage-counter.upgrade')"
          show-label
          variant="tonal"
          @track-click="$tracking.event('AppBar', 'Clicked', 'Upgrade', 'Toad')"
          v-if="isEntryPlan"
        />
        <NewsIcon
          v-if="news"
          :news="news"
          :unreadNewsCount="unreadNewsCount"
          @news-click="handleItemClick($event)"
        />
      </div>
      <v-app-bar-nav-icon
        v-if="$auth.isAuthenticated"
        color="primary"
        @click.stop="showDrawer = !showDrawer"
        style="font-size: 26px"
      />
    </v-app-bar>
    <!-- TODO: check unsupported prop: clipped, app -->
    <NavigationDrawer
      :is-drawer-collapsible="isDrawerCollapsible"
      :show-drawer="showDrawer"
      :is-entry-plan="isEntryPlan"
      :display="display"
      @track-click="trackClick($event)"
      @go-home="goHome"
      @logout="logout"
      @navigation-click="handleItemClick($event)"
      :nav-items="navItems"
      :unread-news-count="unreadNewsCount"
      :company="company"
      :filtered-nav-items-bottimmo="filteredNavItemsBottimmo"
      product-type=""
    />
  </div>
</template>

<script>
import { productFruits } from 'product-fruits'
import { useDisplay } from 'vuetify'

import logo from '@/assets/img/logo.png'
import featureNames from '@/lib/featureNames'
import { ProductType as ProductTypes } from '@/lib/productTypes'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'
import {
  BASIC_NAVIGATION,
  ONBOARDING_NAVIGATION,
} from '@/modules/navigation/data/navigationItems.js'
import { getActiveNavItems, navItemsBottimmo } from '@/modules/navigation/helper/navigation.js'
import NavigationDrawer from '@/modules/navigation/NavigationDrawer.vue'
import NavigationImage from '@/modules/navigation/NavigationImage.vue'
import NewsIcon from '@/modules/navigation/NewsIcon.vue'
import UpgradeButton from '@/modules/navigation/UpgradeButton.vue'

import COMPANY_BASE from '../../queries/CompanyBase.gql'

export default {
  components: {
    NavigationDrawer,
    NavigationImage,
    NewsIcon,
    UpgradeButton,
  },
  mixins: [brandingMixin, featureMixin],
  inject: ['role'],
  props: {
    isDrawerCollapsible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logo,
      // Will be initially set to boolean by the drawer component
      // depending on the viewport size
      showDrawer: null,
      featureNames,
      unreadNewsCount: 0,
      display: useDisplay(),
      ProductTypes,
      isUsageCounterActive: false,
    }
  },
  computed: {
    isMobile() {
      return this.display.smAndDown
    },
    languageCode() {
      return this.$i18n.locale
    },
    productType() {
      return this.company?.productType
    },
    isEntryPlan() {
      const entryPlans = [
        ProductTypes.BEGINNER,
        ProductTypes.BEGINNER_TRIAL,
        ProductTypes.LIGHT,
        ProductTypes.PIONEER,
      ]
      return this.productType && entryPlans.includes(this.productType)
    },
    inOnboarding() {
      return this.company?.state.stringValues.includes('ONBOARDING')
    },
    isInactive() {
      return (
        this.company?.state.stringValues.includes('CREATED') ||
        this.company?.state.stringValues.includes('INACTIVE')
      )
    },
    navItems() {
      if (this.inOnboarding) {
        return getActiveNavItems(ONBOARDING_NAVIGATION, this.$features, this.role)
      }

      return getActiveNavItems(BASIC_NAVIGATION, this.$features, this.role)
    },
    navItemsBottimmo() {
      if (!this.company) {
        return []
      }
      return navItemsBottimmo(
        this.languageCode,
        this.inOnboarding,
        this.isInactive,
        this.$features,
        this.role
      )
    },
    filteredNavItemsBottimmo() {
      return this.navItemsBottimmo.filter(({ trackingLabel }) => {
        if (trackingLabel === 'News') {
          return !this.$vuetify.display.mdAndDown
        }
        return true
      })
    },
    news() {
      return this.navItemsBottimmo.find(({ trackingLabel }) => trackingLabel === 'News')
    },
  },
  watch: {
    showDrawer(val) {
      if (val) {
        productFruits.safeExec(() => {
          this.setupNewsList()
        })
      }
    },
  },
  mounted() {
    productFruits.safeExec(() => {
      this.setupNewsList()
    })
  },
  apollo: {
    company: {
      query: COMPANY_BASE,
      variables() {
        return {
          id: this.$auth.user.companyId,
        }
      },
      skip() {
        return this.$auth.loading || !this.$auth.isAuthenticated
      },
    },
  },
  methods: {
    setupNewsList() {
      const el = document.getElementById('news')
      if (!el) {
        return
      }
      try {
        window.productFruits.api.announcementsV2.attachNewsWidgetToElement(el)

        window.productFruits.api.announcementsV2.listen('newsfeed-unread-count-changed', (data) => {
          this.unreadNewsCount = data.count
        })
      } catch (e) {
        console.error('Could not load news list', e)
      }
    },
    isActive(item) {
      return item.to && this.$route.path === item.to
    },
    logout() {
      this.trackClick('Logout')
      this.$auth.logout()
    },
    goHome() {
      this.$router
        .push({
          name: this.$auth.isAuthenticated ? 'dashboard' : 'home',
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            throw err
          }
        })
    },
    handleItemClick(href) {
      if (this.isMobile) {
        this.showDrawer = false
      }

      if (href !== 'News') {
        this.$router.push(href)
      }

      this.trackClick(href)
    },
    trackClick(label) {
      this.$tracking.event('Sidebar', 'Clicked', label)
    },
  },
}
</script>

<style scoped>
:deep(.v-list--density-compact .v-list-item-title) {
  font-size: 0.75rem !important;
}

:deep(.v-toolbar__content) {
  padding: 4px 16px;
}

:deep(.v-toolbar__content > .v-btn:last-child) {
  margin-inline-end: -12px;
  width: 40px;
}

:deep(.v-navigation-drawer) {
  top: 0px !important;
  height: 100% !important;
  z-index: 10000 !important;
}

.v-navigation-drawer--mini-variant .v-list-item > *:first-child {
  margin-right: 0px !important;
}

.group-header :deep(.v-list-group__header) {
  padding: 0;
}

.navbar .v-list-item--active {
  border-radius: 10px;
}

.navbar .v-list-item--active:before {
  opacity: 0;
}

.navbar .v-list-item:before {
  border-radius: 10px;
}

.navbar {
  z-index: 10;
}

@media (max-width: 1260px) {
  /*Bigger z-index then product fruits containers*/
  .navbar {
    z-index: unset;
  }
  .v-navigation-drawer--open,
  .v-menu__content {
    z-index: 10002992 !important;
  }
}
</style>
