<template>
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <gradient-helper />

    <circle
      cx="50"
      cy="50"
      r="47"
      :stroke="stroke ? 'url(#svg-gradient)' : 'none'"
      :stroke-width="strokeWidth"
      :fill="fill ? 'url(#svg-gradient)' : 'none'"
    />
  </svg>
</template>

<script>
import svg from '@/mixins/svg'

import GradientHelper from './GradientHelper.vue'
export default {
  components: { GradientHelper },
  mixins: [svg],
}
</script>
