import features from '@/lib/features'
import { getInstance } from '@/plugins/feature-config'

import { getInstance as getAuthInstance } from '../auth/index'

export function feature(apolloProvider, featureSlug, checkConfigIsActive) {
  return async (to, from, next) => {
    const authService = getAuthInstance()
    const companyId = authService?.user?.companyId
    if (['company-creation', 'logout'].includes(to.name)) return next()

    let feature
    if (companyId) {
      const featureConfig = getInstance()
      await featureConfig.loadAllFeaturesForCompany(companyId, { apolloProvider, reload: false })
      feature = featureConfig.feature(featureSlug)
    } else {
      feature = features[featureSlug]
    }
    if (!feature) {
      throw new Error(`Feature not found: ${featureSlug}`)
    }
    const isNotAllowed = checkConfigIsActive && !checkConfigIsActive(feature.config)
    if (!feature.isActive || isNotAllowed) {
      return next({ name: 'dashboard', replace: true })
    }
    next()
  }
}
