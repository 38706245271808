<template>
  <v-row
    :style="cssProps"
    :class="{ wrapper: !isMobile, 'wrapper-mobile': isMobile }"
    class="d-flex justify-space-between align-center h-full"
  >
    <v-col
      cols="6"
      v-if="!isMobile"
      class="markero-logo"
    >
      <v-img
        alt="Company Registration Logo"
        :src="markero"
        cover
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
      :class="{ 'content-wrapper': !isMobile, 'content-wrapper-mobile': isMobile }"
      class="h-full col-md-6 flex-column d-flex justify-space-between"
    >
      <div
        class="flex-1 reset-password px-2 px-sm-0 mx-auto col-sm-10 col-lg-6 inner-container"
        :class="{ 'reset-password': !submittedEmail, 'reset-password-submitted': submittedEmail }"
      >
        <div class="row justify-center text-center">
          <div class="content">
            <confirmation
              v-if="submittedEmail"
              :email="submittedEmail"
              @resend="submittedEmail = null"
              @back="back"
            />
            <template v-if="!submittedEmail">
              <e-mail-form
                class="pb-2"
                @submitted="handleSubmitted"
                @back="back"
              />
              <v-divider class="pb-6" />
              <div class="text-center pb-6">
                <h2 class="video-title">
                  {{ $t('reset-password.video.title') }}
                </h2>
              </div>
              <InstructionVideo />
            </template>
          </div>
        </div>
      </div>

      <div class="flex-1 terms-wrapper mb-2 mx-auto">
        <div
          :class="{ terms: !isMobile, 'terms-mobile ma-2': isMobile }"
          class="justify-center d-flex flex-1"
        >
          <IubendaDocumentLink
            type="terms"
            class="mx-1 mx-sm-2"
            @click.stop
          />
          <IubendaDocumentLink
            class="mx-1 mx-sm-2"
            type="privacyPolicy"
            @click.stop
          /><br />
          <IubendaConsentLink class="mx-1 mx-sm-2" />
          <a
            :href="imprintUrl"
            target="_blank"
            class="mx-1 mx-sm-2"
            rel="noopener noreferrer"
            >{{ $t('navigation.labels.imprint') }}</a
          >
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import markero from '@/assets/img/markero-registration.png'
import { email, required } from '@/lib/validation'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'
import InstructionVideo from '@/modules/auth/PasswordResetRequest/InstructionVideo.vue'
import IubendaConsentLink from '@/modules/iubenda/ConsentPreferencesLink.vue'
import IubendaDocumentLink from '@/modules/iubenda/DocumentLink.vue'

import EMailForm from '../EMailForm'
import Confirmation from '../markero/Confirmation'

export default {
  components: {
    InstructionVideo,
    Confirmation,
    EMailForm,
    IubendaConsentLink,
    IubendaDocumentLink,
  },
  mixins: [featureMixin, brandingMixin],
  data: () => ({
    submitting: false,
    submittedEmail: null,
    email: '',
    error: null,
    markero,
  }),
  computed: {
    formReady() {
      return this.email.length
    },
    errorMessages() {
      return {
        emptyFields: 'alerts.signup.empty-fields',
        userExists: 'alerts.signup.user-exists',
        unknown: 'alerts.signup.unknown',
      }
    },
    cssProps() {
      return {
        '--mid-yellow': this.$vuetify.theme.global?.current?.colors['markero-mid-yellow'],
        '--dark-blue': this.$vuetify.theme.global?.current?.colors['markero-dark-blue'],
        '--dark-grey': this.$vuetify.theme.global?.current?.colors['markero-dark-grey'],
        '--light-orange': this.$vuetify.theme.global?.current?.colors['markero-light-orange'],
      }
    },
    isMobile() {
      return this.$vuetify.display.mdAndDown
    },
  },
  created() {
    this.rules = { email, required }
  },
  methods: {
    handleSubmitted({ email }) {
      this.submittedEmail = email
    },
    getErrorMessage(code) {
      return this.errorMessages[code] || this.errorMessages.unknown
    },
    back() {
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style scoped>
/* Wrapper Styles */
.wrapper,
.wrapper-mobile {
  background-color: white;
}

.wrapper,
.markero-logo {
  height: 100vh;
}

.wrapper-mobile {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Content Wrapper Styles */
.content-wrapper {
  display: flex;
  flex-direction: column !important;
  min-height: 100vh;
}

.reset-password {
  margin-top: 15%;
}

.reset-password-submitted {
  margin-top: 35%;
}

/* Markero Logo Styles */
.markero-logo {
  background-color: var(--mid-yellow);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

/* Reset Password Styles */
.video-title,
.terms {
  color: #252525;
  font-family: 'Poppins', sans-serif;
}

.video-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.inner-container,
.terms-wrapper {
  max-width: 500px;
}

.terms,
.terms-mobile {
  font-size: 14px;
}

.terms-mobile {
  margin-top: 80%;
}

.terms-error .terms-mobile {
  margin-top: 70%;
}

.content {
  width: 100%;
  max-width: 450px;
}

:deep(.v-text-field--outlined > .v-input__control > .v-input__slot) {
  min-height: 48px;
}

:deep(.v-btn:not(.v-btn--round).v-size--default) {
  width: 47%;
}

:deep(.v-text-field--outlined .v-label) {
  top: 16px;
}

:deep(.v-text-field--enclosed .v-input__prepend-inner) {
  margin-top: 13px;
}
</style>
