query features {
  features {
    slug
    name
    isEnabled
    isDefaultActive
    isUpsellingEnabled
    config
    isActive
  }
}
