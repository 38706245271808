<template>
  <v-list
    density="compact"
    class="bottom-navigation"
  >
    <v-list-item
      v-for="item in navItems"
      class="nav-item"
      :id="item.trackingLabel.toLowerCase()"
      :key="item.to"
      :to="item.to || null"
      :href="item.href || null"
      :target="item.target"
      :rel="item.rel"
      @click="handleItemClick(item)"
      height="40"
    >
      <template #prepend>
        <span
          v-if="item.trackingLabel === 'News' && unreadNewsCount > 0"
          class="unread-messages-count"
        >
          {{ unreadNewsCount }}
        </span>
        <v-icon>{{ item.icon }}</v-icon>
      </template>
      <template #title>
        <v-badge
          v-if="item.badge && item.badge.condition({ showRegeneration })"
          :color="item.badge.color"
          :icon="item.badge.icon"
        >
          <v-list-item>
            <v-list-item-title>{{ $t(item.label) }}</v-list-item-title>
          </v-list-item>
        </v-badge>
        <v-list-item v-else>
          <v-list-item-title>{{ $t(item.label) }}</v-list-item-title>
        </v-list-item>
      </template>
      <template
        #append
        v-if="isDrawerOpen"
      >
        <v-list-item-action v-if="item.href">
          <v-icon
            color="grey lighten-1"
            size="14"
          >
            mdi-open-in-new
          </v-icon>
        </v-list-item-action>
      </template>
    </v-list-item>
  </v-list>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'

import { NavItem } from './models/Navitem'
defineProps<{
  navItems: PropType<NavItem[]>
  isDrawerOpen?: PropType<boolean>
  unreadNewsCount?: PropType<number>
  showRegeneration?: PropType<boolean>
}>()

const emit = defineEmits<{
  'navigation-click': () => void
}>()

const handleItemClick = (item: NavItem) => {
  emit('navigation-click', item.trackingLabel)
}
</script>

<style scoped>
.bottom-navigation {
  margin-top: 0;
  min-height: fit-content;
}

.nav-item {
  padding-left: 10px !important;
}

.unread-messages-count {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -1px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  z-index: 9;
}

.unread-messages-count {
  top: -4px;
}
</style>
