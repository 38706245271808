<template>
  <v-img
    :alt="alt"
    :class="customClass"
    :src="src"
    :width="width"
    :height="height"
    min-height="16"
    style="flex: unset"
    @click="goHome"
  />
</template>
<script lang="ts" setup>
import { inject } from 'vue'
import { useRouter } from 'vue-router'

defineProps<{
  alt?: string
  src: string
  customClass?: string
  width?: number
  height?: number
}>()

const router = useRouter()
const auth = inject('auth')
const goHome = async () => {
  try {
    await router.push({
      name: auth?.isAuthenticated ? 'dashboard' : 'home',
    })
  } catch (err) {
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  }
}
</script>
