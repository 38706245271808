// mixins/featureMixin.js
import featureNames from '@/lib/featureNames'
import { Feature } from '@/plugins/feature-config'

export default {
  components: {
    Feature,
  },

  data() {
    return {
      featureNames,
    }
  },

  methods: {
    isFeatureActive(featureSlug) {
      // Access the feature method from the Vue instance's methods
      return this.$features?.feature?.(featureSlug) && this.$features.feature(featureSlug).isActive
    },

    getFeature(featureSlug) {
      return this.$features.feature(featureSlug)
    },
  },
}
