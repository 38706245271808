<template>
  <v-container
    class="dashboard-presentation py-0 my-0 py-md-2"
    fluid
  >
    <v-row>
      <!-- Left Column -->
      <v-col
        class="pa-0 ma-0 pr-md-2"
        cols="12"
        md="6"
      >
        <v-card
          border
          class="box pa-4 mr-md-2"
        >
          <v-container
            class="pa-0"
            fluid
          >
            <v-row>
              <v-col
                class="pa-0 ma-0"
                cols="12"
              >
                <ContactAcquisition
                  @update-stats="handleContactAquisitionStatsUpdate"
                  :is-new-user="isNewUser"
                />
              </v-col>
              <v-col
                class="pa-0 ma-0"
                cols="12"
              >
                <Impressions :is-new-user="isNewUser" />
              </v-col>
              <v-col
                class="pa-0 ma-0"
                cols="12"
              >
                <EmailFunnels
                  @update-is-new-user="handleIsNewUser"
                  @update-stats="handleEmailFunnelsStatsUpdate"
                  :is-new-user="isNewUser"
                />
              </v-col>
              <v-col
                class="pa-0 ma-0"
                cols="12"
              >
                <GainedContacts
                  :statistics="statistics"
                  :loading="loading"
                  :is-new-user="isNewUser"
                />
              </v-col>
              <v-col
                class="pa-0 ma-0"
                cols="12"
              >
                <AddedValue
                  :statistics="statistics"
                  :loading="loading"
                  :total-time-saved="totalTimeSaved"
                  :total-money-saved="totalMoneySaved"
                  v-if="!isNewUser"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <!-- Right Column -->
      <v-col
        class="pa-0 ma-0 mt-4 mt-md-0"
        cols="12"
        md="6"
      >
        <Permission permission="lead:read">
          <Feature
            v-slot="{ feature }"
            :feature-slug="featureNames.COMPANY"
          >
            <Feature
              :feature-slug="featureNames.VALUATION"
              class="w-10"
            >
              <lead-map
                v-if="leads"
                :leads="leads"
              />
              <v-card
                v-else
                border
                class="map-loader"
                style="height: 400px"
              >
                <v-skeleton-loader
                  height="400px"
                  type="image"
                />
              </v-card>
            </Feature>
            <lead-interactivity
              class="mt-4"
              v-if="feature.config.showLeadInteractivity"
            />
          </Feature>
        </Permission>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue'

import Permission from '@/components/Permission'
import featureNames from '@/lib/featureNames'
import AddedValue from '@/modules/dashboard/AddedValue.vue'
import ContactAcquisition from '@/modules/dashboard/ContactAcquisition.vue'
import EmailFunnels from '@/modules/dashboard/EmailFunnels.vue'
import GainedContacts from '@/modules/dashboard/GainedContacts.vue'
import Impressions from '@/modules/dashboard/Impressions.vue'
import LeadMap from '@/modules/map/index.vue'
import LeadInteractivity from '@/modules/statistics/LeadInteractivity.vue'
import { Feature } from '@/plugins/feature-config'

defineProps({
  statistics: Object as PropType<Record<string, any>>,
  leads: Object as PropType<Record<string, any>>,
  loading: Boolean,
})

const isNewUser = ref(false)
const contactAquisitionTimeSaved = ref(0)
const contactAquisitionMoneySaved = ref(0)
const emailFunnelsTimeSaved = ref(0)
const emailFunnlesMoneySaved = ref(0)
const totalTimeSaved = computed(
  () => contactAquisitionTimeSaved.value + emailFunnelsTimeSaved.value
)
const totalMoneySaved = computed(
  () => contactAquisitionMoneySaved.value + emailFunnlesMoneySaved.value
)

interface StatsUpdate {
  timeSaved: number
  moneySaved: number
}

const handleContactAquisitionStatsUpdate = ({ timeSaved, moneySaved }: StatsUpdate) => {
  contactAquisitionTimeSaved.value = timeSaved
  contactAquisitionMoneySaved.value = moneySaved
}

const handleEmailFunnelsStatsUpdate = ({ timeSaved, moneySaved }: StatsUpdate) => {
  emailFunnelsTimeSaved.value = timeSaved
  emailFunnlesMoneySaved.value = moneySaved
}

const handleIsNewUser = ({ sent }: { sent: number }) => {
  isNewUser.value = !(sent > 0)
}
</script>

<style>
.dashboard-presentation {
  .card {
    border-radius: 10px;
    color: #ffffff;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
  .vertical-divider {
    font-size: 22px;
    opacity: 0.3;
    color: #ffffff;
  }
  .save-tme-money-numbers {
    display: flex;
    align-items: center;
  }
  .save-time-money {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .funnel {
    margin: 0 auto;
    position: relative;
    margin-bottom: 10px;
  }

  .grid {
    display: grid;
  }

  .icon {
    font-size: 18px;
  }

  .title {
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  .label {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .number {
    color: #ffffff;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .button {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  .button-invite-more,
  .button-do-more {
    width: 100%;
  }

  @media (max-width: 960px) {
    .funnel {
      width: 100%;
    }
    .funnel::after {
      top: 100% !important;
      left: 12px !important;
      right: 12px !important;
    }
    .title {
      font-size: 14px;
    }
    .label {
      font-size: 12px;
    }
    .number {
      font-size: 16px;
    }
    .button {
      font-size: 12px;
    }
    .v-btn--size-default {
      width: 100%;
    }

    .icon {
      font-size: 16px;
    }
  }
}
</style>
